import React, { ReactNode } from "react";
import styled from "styled-components";

interface OnboardingCardProps {
  children: ReactNode;
  $margin?: string;
}

export const OnboardingCard: React.FC<OnboardingCardProps> = ({
  children,
  $margin,
}) => {
  return (
    <Container>
      <MainWrapper $margin={$margin}>{children}</MainWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  max-height: 100%;
  overflow-y: auto;
`;

const MainWrapper = styled.div<OnboardingCardProps>`
  height: 100%;
  width: 100%;
  margin: ${(props) => props.$margin || "0px 40px"};
  max-height: 100%;
`;
