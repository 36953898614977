import React from "react";
import styled from "styled-components";
import Button from "../../helpers/components/Button";
import bgImageFlower from "../../images/bg_image_flower.svg";
import babyfoot from "../../images/logo_feet.svg";
import weightIcon from "../../images/weightIcon.svg";
import heightIcon from "../../images/heightIcon.svg";
import {
  BackgroundImage,
  ButtonWrapper,
} from "../authentication/shared/SharedStyles";
import { OnboardingCard } from "../shared/OnboardingCard";
import { colors } from "../../styles/style";
import { useNavigate } from "react-router-dom";
import { useBabyContext } from "../../context/BabyContext";
import {
  formatLongDate,
  sortMeasurementsByDateAsc,
} from "../../common/helpers";
import LoadingSpinner from "../../common/LoadingSpinner";

export const BabyInfo = () => {
  const navigate = useNavigate();

  const { selectedBaby } = useBabyContext();
  if (!selectedBaby) {
    return <LoadingSpinner />;
  }
  const firstAddedMeasurement =
    selectedBaby?.measurements.length > 0
      ? sortMeasurementsByDateAsc(selectedBaby.measurements)
      : null;
  return (
    <OnboardingCard $margin="0">
      <BackgroundImage
        $position="fixed"
        $left="50%"
        $top="0%"
        $transform="translate(-50%)"
        $width="390px"
        alt="Frame"
        src={bgImageFlower}
      />
      <MainContainer>
        <ContentContainer>
          <BabyInfoWrapper>
            <BabyImageWrapper>
              <BackgroundOverlay />
              <BabyImage
                alt="Baby"
                // src={getResizedImageUrl(
                //   selectedBaby?.profile_picture,
                //   imageSizes.large
                // )}
                src={selectedBaby?.profile_picture}
                onError={(e) => {
                  e.currentTarget.src = selectedBaby?.profile_picture;
                }}
              />
            </BabyImageWrapper>
            <BabyHeader>{selectedBaby?.name}</BabyHeader>
            <BabyDate>
              {selectedBaby?.date_of_birth
                ? formatLongDate(selectedBaby.date_of_birth)
                : ""}
            </BabyDate>
            <BabyMeasurement>
              {firstAddedMeasurement && (
                <MeasurementItem>
                  <MeasurementIcon alt="Height" src={heightIcon} />
                  <MeasurementText>
                    {`${firstAddedMeasurement?.height} cm`}
                  </MeasurementText>
                </MeasurementItem>
              )}
              {firstAddedMeasurement && (
                <MeasurementItem>
                  <MeasurementIcon alt="Weight" src={weightIcon} />
                  <MeasurementText>
                    {`${firstAddedMeasurement?.weight} gr`}
                  </MeasurementText>
                </MeasurementItem>
              )}
            </BabyMeasurement>
          </BabyInfoWrapper>
          <ButtonWrapper>
            <Button $button_type="colored" onClick={() => navigate("/feed")}>
              Continue
            </Button>
          </ButtonWrapper>
        </ContentContainer>
      </MainContainer>
    </OnboardingCard>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 48px;
  margin-top: 120px;
`;

const BabyInfoWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 38px;
  border: 2px solid ${colors.coralPink};
  padding-top: 90px;
  padding-bottom: 30px;
  margin-bottom: 15px;
  @media (max-height: 600px) {
    padding-top: 50px;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0%;
    left: 50%;
    width: 90px;
    height: 90px;
    background-color: ${colors.mistyRoseColor};
    border-radius: 28px;
    background-image: url(${babyfoot});
    background-size: 55px 55px;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
    border-top: inherit;
    border-top-color: transparent;
  }
`;

const BabyImageWrapper = styled.div`
  position: relative;
  display: flex;
  width: 282px;
  height: 282px;
  max-width: 100%;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  @media (max-width: 370px) {
    width: 246px;
    height: 246px;
  }
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 119, 169, 0) 0%,
    rgba(252, 221, 202, 0.62) 62.5%
  );
  mix-blend-mode: soft-light;
`;

const BabyImage = styled.img`
  width: 226px;
  height: 226px;
  max-width: 100%;
  border-radius: 50%;
  margin: 0 30px;
  border: 4px solid ${colors.white};
  z-index: 1;
  @media (max-width: 370px) {
    width: 190px;
    height: 190px;
  }
`;

const BabyHeader = styled.h2`
  color: ${colors.warmthBrown};
  text-align: center;
  font-size: 40.449px;
  font-style: normal;
  font-weight: 450;
  line-height: 62.022px; /* 153.333% */
  letter-spacing: -0.607px;
  margin-top: 46px;

  @media (max-height: 600px) {
    margin-top: 20px;
  }
`;

const BabyDate = styled.p`
  color: ${colors.darkBrown};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 30px;
  letter-spacing: -0.3px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const BabyMeasurement = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  margin-top: 10px;
  color: ${colors.darkBrown};
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 150.8%; /* 30.16px */
  letter-spacing: -0.3px;
`;

const MeasurementItem = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 8px;
  text-align: center;
`;

const MeasurementIcon = styled.img`
  width: 32px;
  height: 32px;
  max-width: 100%;
`;

const MeasurementText = styled.div`
  color: ${colors.darkBrown};
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 30px;
  letter-spacing: -0.3px;
`;
