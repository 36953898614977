import React from "react";
import styled from "styled-components";
import goBackIcon from "../../../images/goBackIcon.svg";
import { NotificationsIcon } from "../../feed/common/shared/NotificationsIcon";
import { useNavigate } from "react-router-dom";
import { FlexRowBetweenCenter, MediumIcon } from "../../shared/SharedStyles";

export const ProfileHeader = () => {
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <Container>
      <MediumIcon src={goBackIcon} onClick={goBackHandler} />
      <NotificationsIcon />
    </Container>
  );
};
const Container = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px 16px 0 16px;
  width: 100%;
`;
