import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { MonthlyDiaryCard } from "./MonthlyDiaryCard";
import { SmallIcon } from "../../../shared/SharedStyles";
import { colors } from "../../../../styles/style";
import addIcon from "../../../../images/addIcon.svg";
import { AddNewDiaryModal } from "./AddNewDiary";
import { getMonthlyDiaryByBaby } from "../../../../api";
import { useBabyContext } from "../../../../context/BabyContext";
import LoadingSpinner from "../../../../common/LoadingSpinner";
import { AuthContext } from "../../../../context/AuthContext";

interface MonthlyDiaryListProps {
  selectedYear: string;
}

export const MonthlyDiaryList: React.FC<MonthlyDiaryListProps> = ({
  selectedYear,
}) => {
  const { hasActivePayment } = React.useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [monthToAddOrUpdate, setMonthToAddOrUpdate] = useState<any>(null);
  const [monthlyDiaryData, setMonthlyDiaryData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { selectedBabyId } = useBabyContext();

  const fetchMonthlyDiary = async () => {
    setLoading(true);
    await getMonthlyDiaryByBaby(selectedBabyId, selectedYear)
      .then(({ data }) => {
        setMonthlyDiaryData(data);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setMonthlyDiaryData([]);
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    fetchMonthlyDiary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  return (
    <>
      {loading && <LoadingSpinner />}
      <CardContainer $firstRow={true}>
        {monthlyDiaryData && monthlyDiaryData.length > 0 && (
          <div
            onClick={() => {
              if (hasActivePayment) {
                setShowModal(true);
                setMonthToAddOrUpdate({
                  ...monthlyDiaryData[0],
                  label: monthlyDiaryData[0].text,
                });
              }
            }}
          >
            <MonthlyDiaryCard
              key={0}
              image={monthlyDiaryData[0].image}
              month={monthlyDiaryData[0].month}
              text={monthlyDiaryData[0].text}
              firstRow={true}
            />
          </div>
        )}
      </CardContainer>
      <CardContainer>
        {monthlyDiaryData &&
          monthlyDiaryData.slice(1).map((data: any, index: any) => (
            <div
              key={index + 1}
              onClick={() => {
                setShowModal(true);
                setMonthToAddOrUpdate({ ...data, label: data.text });
              }}
            >
              <MonthlyDiaryCard
                key={index + 1}
                image={data.image}
                month={data.month}
                text={data.text}
              />
            </div>
          ))}
        {loading ? (
          <LoadingSpinner />
        ) : (
          <AddDiaryEntryContainer
            onClick={() => {
              if (hasActivePayment) {
                setShowModal(true);
                setMonthToAddOrUpdate({
                  year: selectedYear,
                  month:
                    monthlyDiaryData?.length > 0
                      ? monthlyDiaryData[monthlyDiaryData?.length - 1]?.month +
                        1
                      : 1,
                });
              }
            }}
            disabled={!hasActivePayment}
          >
            <SmallIcon src={addIcon} alt="Add Diary Entry" />
          </AddDiaryEntryContainer>
        )}
      </CardContainer>
      {showModal && (
        <AddNewDiaryModal
          showModal={showModal}
          handleSetShowModal={() => setShowModal(!showModal)}
          dataToAddOrUpdateFromParent={monthToAddOrUpdate}
          handleRefreshParentData={fetchMonthlyDiary}
          parentComponentName="monthlyDiary"
          monthlyDiaryData={monthlyDiaryData}
        />
      )}
    </>
  );
};

interface CardContainerProps {
  $firstRow?: boolean;
}

const CardContainer = styled.div<CardContainerProps>`
  margin: 12px 24px;
  display: grid;
  grid-template-columns: ${(props) => (props.$firstRow ? "1fr" : "1fr 1fr")};
  gap: 12px;
`;
const AddDiaryEntryContainer = styled.div<{ disabled?: boolean }>`
  width: 100%;
  border-radius: 16px;
  border: 1px dashed ${colors.borderWhite};
  box-shadow: 0px 18px 30px 0px ${colors.warmthGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 154px;
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`;
