import React, { useContext, useState, useEffect } from "react";
import { NavigationMenu } from "../shared/NavigationMenu";
import { AccountBabies } from "./common/AccountBabies";
import { AccountOwner } from "./common/AccountName";
import { AccountPicture } from "./common/AccountPicture";
import { SettingsCard } from "./common/SettingsCard";
import { SettingsHeader } from "./common/SettingsHeader";
import { SharedButton } from "./common/shared/SharedButton";
import deleteIcon from "../../images/deleteIcon.svg";
import congratsImage from "../../images/congrats.svg";
import { IDetails, InformationSection } from "./common/InformationSection";
import { AuthContext } from "../../context/AuthContext";
import { DeleteAccountConfirmation } from "./common/DeleteAccountConfirmation";
import { createSubscriptionSession, deleteAccount } from "../../api";
import { SubscriptionUpgrade } from "../shared/SubscriptionUpgrade";
import { useNavigate } from "react-router-dom";
import { InvitationList } from "../profile/common/InvitationList";

export const SettingsPage = () => {
  const navigate = useNavigate();
  const location = window.location;
  const authContext = useContext(AuthContext);
  const { logout, authUser, hasActivePayment } = authContext;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const subscriptionFailedParam = params.get("subscription-failed");

    if (subscriptionFailedParam !== null) {
      subscriptionFailedParam === "false"
        ? navigate("/feedback", {
            state: {
              headerText: "Subscription Successful!",
              bodyText: "You have subscribed to the new plan.",
              buttonText: "Close",
              navigateTo: "/settings",
              image: congratsImage,
              success: true,
            },
          })
        : navigate("/feedback", {
            state: {
              headerText: "Subscription Failed!",
              bodyText: "Please go back and try again.",
              buttonText: "Go Back",
              navigateTo: "/settings",
              image: "",
              success: false,
            },
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, refresh]);
  const accountInformationData: IDetails[] = [
    // { title: "Your plan", value: "Freemium" },
    { title: "Your E-mail", value: authUser?.email || "No Data" },
    // { title: "Your password", value: "********" },
  ];

  // const personalInformationData: IDetails[] = [
  //   { title: "Place of Birth", value: "No Data" },
  //   { title: "Date of Birth", value: "No Data" },
  //   { title: "Spouse", value: "No Data" },
  // ];
  const handleDeleteAccount = async () => {
    setLoading(true);
    try {
      await deleteAccount().then(() => {
        logout();
      });
      setLoading(false);
    } catch (error) {}
  };

  const handleUpgradeClick = async () => {
    setButtonLoading(true);
    const subscriptionPrice = process.env.REACT_APP_SUBSCRIPTION_PRICE;
    await createSubscriptionSession({
      priceId: subscriptionPrice,
    }).then(({ data }) => {
      window.location.href = data.createdSession;
    });
    setButtonLoading(false);
  };

  return (
    <React.Fragment>
      <SettingsCard>
        <SettingsHeader />
        {!hasActivePayment && (
          <SubscriptionUpgrade
            margin="0px"
            text={`You are currently not subscribed to our app. Please subscribe to access all features.`}
            onClick={handleUpgradeClick}
            disabled={buttonLoading}
          />
        )}
        <AccountPicture />
        <AccountOwner />
        <AccountBabies refresh={refresh} />
        <InvitationList onRefresh={() => setRefresh(!refresh)} />

        <InformationSection
          title="Account Information"
          data={accountInformationData}
          showEditIcon={false}
        />
        {/* <SubscriptionInfo /> */}
        {/* <InformationSection
          title="Your personal information"
          data={personalInformationData}
        /> */}
        <SharedButton
          iconSrc={deleteIcon}
          buttonText="Delete Account"
          $margin="36px 0 0 0"
          onClick={() => setShowModal(true)}
        />
        <SharedButton
          buttonText="Log Out"
          onClick={() => {
            logout();
          }}
          $margin="20px 0 0 0"
        />
      </SettingsCard>
      <NavigationMenu />
      {showModal && (
        <DeleteAccountConfirmation
          showModal={showModal}
          handleSetShowModal={() => setShowModal(!showModal)}
          title="Confirmation"
          handleActionClick={handleDeleteAccount}
          loading={loading}
          paragraphText="This action will permanently delete your account and all related
          models. This cannot be undone."
          headerText="Are you sure you want to proceed?"
        />
      )}
    </React.Fragment>
  );
};
