import React from "react";
import styled from "styled-components";
import Button from "../../helpers/components/Button";
import { OnboardingCard } from "../shared/OnboardingCard";
import bgImageFlower from "../../images/bg_image_flower.svg";
import logoFeet from "../../images/logo_feet.svg";
import {
  BackgroundImage,
  ButtonWrapper,
  Headline,
} from "./shared/SharedStyles";
import { useNavigate } from "react-router-dom";
import { colors } from "../../styles/style";

export const AuthenticationPage = () => {
  const navigate = useNavigate();

  const handleLoginButtonClick = () => {
    navigate("/login");
  };
  const handleRegisterButtonClick = () => {
    navigate("/register");
  };

  return (
    <OnboardingCard>
      <BackgroundImage
        $position="absolute"
        $left="50%"
        $top="40%"
        $transform="translate(-50%, -50%)"
        $width="390px"
        alt="Frame"
        src={bgImageFlower}
      />
      <MainContainer>
        <IconWrapper>
          <IconImage alt="Group" src={logoFeet} />
        </IconWrapper>
        <TextWrapper style={{ paddingTop: "30px" }}>Welcome to</TextWrapper>
        <Headline>Little Feather</Headline>

        <ButtonWrapper>
          <Button $button_type="colored" onClick={handleLoginButtonClick}>
            Log In
          </Button>
          <Button $button_type="primary" onClick={handleRegisterButtonClick}>
            Sign Up
          </Button>
        </ButtonWrapper>
      </MainContainer>
    </OnboardingCard>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  padding-top: 190px;
  padding-bottom: 40px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.warmthBrown};
  border-radius: 24px;
  height: 100px;
  width: 100px;
`;

const IconImage = styled.img`
  height: auto;
  width: auto;
  max-width: 63px;
`;

const TextWrapper = styled.span`
  color: #7a5038;
  font-size: 26px;
  font-style: normal;
  font-weight: 450;
  line-height: 62px;
  letter-spacing: -0.404px;
`;
