import React, { useState, useEffect } from "react";
import styled from "styled-components";
import goBackIcon from "../../../images/goBackIcon.svg";
import Button from "../../../helpers/components/Button";
import {
  BigHeaderText,
  FlexRowBetweenCenter,
  MediumIcon,
} from "../../shared/SharedStyles";
import { useNavigate } from "react-router-dom";
import { WeAreInMotionCard } from "./common/WeAreInMotionCard";
import { useBabyContext } from "../../../context/BabyContext";
import {
  addInMotionData,
  getInMotionDataByBaby,
  updateInMotionData,
} from "../../../api";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { weAreInMotionNodes } from "../../../common/constants";
import {
  MilestoneButtonWrapper,
  MilestoneContainer,
  MilestoneWrapper,
} from "../common/SharedComponents";

export const WeAreInMotion = () => {
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate(-1);
  };
  const { selectedBabyId } = useBabyContext();
  const [loading, setLoading] = useState(false);

  const [inMotionNodes, setInMotionNodes] = useState<any>(weAreInMotionNodes);

  const handleDataChange = async (
    imageUrl: string,
    date: any,
    type: string
  ) => {
    setLoading(true);

    const nodeIndex = inMotionNodes.findIndex(
      (node: any) => node.type === type
    );

    const epochDate = Math.floor(new Date(date).getTime() / 1000);
    const dataToAddOrUpdate = {
      baby_id: selectedBabyId,
      image: imageUrl,
      type: type,
      created_at: epochDate,
    };
    if (nodeIndex !== -1) {
      const node = inMotionNodes[nodeIndex];
      if (!node.databaseImage) {
        // Add new data
        await addInMotionData(dataToAddOrUpdate);
      } else {
        // Update existing data
        await updateInMotionData(selectedBabyId, dataToAddOrUpdate);
      }

      // Update the state to reflect changes
      await fetchInMotionData();
      // setInMotionNodes((prevNodes: any[]) => {
      //   const newNodes = [...prevNodes];
      //   newNodes[nodeIndex] = {
      //     ...newNodes[nodeIndex],
      //     databaseImage: imageUrl,
      //     date: date,
      //   };
      //   return newNodes;
      // });
    }

    setLoading(false);
  };
  const fetchInMotionData = async () => {
    setLoading(true);
    await getInMotionDataByBaby(selectedBabyId)
      .then(({ data }) => {
        setInMotionNodes((prev: any) => {
          return prev.map((member: any) => {
            const memberData = data.find((d: any) => d.type === member.type);
            if (memberData) {
              return {
                ...member,
                databaseImage: memberData.image,
                date: memberData.created_at, // Assuming date is in seconds
              };
            }
            return member;
          });
        });
      })
      .catch((err) => {});
    setLoading(false);
  };

  useEffect(() => {
    fetchInMotionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <MilestoneContainer>
        <HeaderContainer>
          <MediumIcon src={goBackIcon} onClick={goBackHandler} />
          <BigHeaderText>We are in motion</BigHeaderText>
          <div></div>
        </HeaderContainer>
        <MilestoneWrapper>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <MotionWrapper>
              {inMotionNodes.map((node: any, index: any) => (
                <WeAreInMotionCard
                  key={index}
                  defaultImage={node.defaultImage}
                  databaseImage={node.databaseImage}
                  type={node.type}
                  text={node.text}
                  date={node.date}
                  onDataChange={handleDataChange}
                />
              ))}
            </MotionWrapper>
          )}
        </MilestoneWrapper>
        <MilestoneButtonWrapper>
          <Button $button_type="colored" onClick={goBackHandler}>
            Go Back
          </Button>
        </MilestoneButtonWrapper>
      </MilestoneContainer>
    </React.Fragment>
  );
};

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const MotionWrapper = styled.div`
  align-items: center;
  display: grid;
  margin: 20px 20px;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;
