import familyTreeIcon from "../images/familyTreeIcon.svg";
import cakeIcon from "../images/cakeIcon.svg";
import firstYearIcon from "../images/firstYearIcon.svg";
import teethIcon from "../images/teethIcon.svg";
import motionIcon from "../images/motionIcon.svg";
import christmasIcon from "../images/christmasIcon.svg";
import calendarIcon from "../images/calendarIcon.svg";
import homeIcon from "../images/homeIcon.svg";
import milestonesIcon from "../images/milestonesIcon.svg";
import profileIcon from "../images/profileIcon.svg";
import settingsIcon from "../images/settingsIcon.svg";
import homeActiveIcon from "../images/homeActiveIcon.svg";
import milestonesActiveIcon from "../images/milestonesActiveIcon.svg";
import profileActiveIcon from "../images/profileActiveIcon.svg";
import settingsActiveIcon from "../images/settingsActiveIcon.svg";
import weareinmotion1 from "../images/weareinmotion1.svg";
import weareinmotion2 from "../images/weareinmotion2.svg";
import weareinmotion3 from "../images/weareinmotion3.svg";
import weareinmotion4 from "../images/weareinmotion4.svg";
import tooth1 from "../images/tooth1.png";
import tooth2 from "../images/tooth2.png";
import tooth3 from "../images/tooth3.png";
import tooth4 from "../images/tooth4.png";
import tooth5 from "../images/tooth5.png";
import tooth6 from "../images/tooth6.png";
import boldtooth1 from "../images/boldtooth1.png";
import boldtooth2 from "../images/boldtooth2.png";
import boldtooth3 from "../images/boldtooth3.png";
import boldtooth4 from "../images/boldtooth4.png";
import boldtooth5 from "../images/boldtooth5.png";
import boldtooth6 from "../images/boldtooth6.png";

export const errorMessages = {
  completeReCaptcha: "Please complete the reCAPTCHA",
  verifyEmailBeforeLogin:
    "Email verification required before login. Please check your inbox for a verification email and follow the instructions to complete the process.",
  wrongCode: "Wrong code entered. Please try again.",
  invalidEmailError: "Please check your email and try again.",
  invalidCredentialError:
    "There's an issue with your credentials. Please try again.",
  wrongPasswordError: "Incorrect password. Please try again.",
  genericError: "Something went wrong. Please try again later.",
  firstNameError: "First Name is required.",
  lastNameError: "Last Name is required.",
  emailAddressError: "Please enter a valid email address.",
  passwordError:
    "Password must contain at least 8 characters, including one capital letter and one symbol (!@#$%^&*)",
  termsAndConditionsError: "Please read and accept the Terms and Conditions",
  toManyLoginRequests:
    "Too many login attempts. Please wait a moment and try again.",
  tooManyRequests:
    "Verification email already sent. Please check your inbox or spam folder. If you still haven't received it, please try again later.",
  passwordResetSuccess:
    "Password reset email sent successfully. Check your inbox or spam folder.",
  passwordResetError:
    "There is no user record corresponding to this email. Please check your email and try again.",
  emailVerificationSent:
    "A verification email has been sent to your inbox. Please verify your account before logging in.",
};
export const months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const milestonesData = [
  {
    iconSrc: familyTreeIcon,
    title: "Family Tree",
    url: "/family-tree",
  },
  { iconSrc: firstYearIcon, title: "My first year", url: "/first-year" },
  { iconSrc: calendarIcon, title: "Month by month", url: "/monthly-diary" },
  {
    iconSrc: motionIcon,
    title: "Birth Certificate",
    url: "/baby-info",
  },
  { iconSrc: cakeIcon, title: "My First Birthday", url: "/first-birthday" },
  { iconSrc: teethIcon, title: "Teeth Chart", url: "/teeth-chart" },
  { iconSrc: motionIcon, title: "We are in motion", url: "/in-motion" },
  { iconSrc: christmasIcon, title: "My first holiday", url: "/first-holiday" },
];

export const navItems = [
  {
    to: "/feed",
    iconSrc: homeIcon,
    activeIcon: homeActiveIcon,
    alt: "Home",
    text: "Home",
  },
  {
    to: "/milestones",
    iconSrc: milestonesIcon,
    activeIcon: milestonesActiveIcon,
    alt: "Milestones",
    text: "Milestones",
  },
  {
    to: "/profile",
    iconSrc: profileIcon,
    activeIcon: profileActiveIcon,
    alt: "Profile",
    text: "Profile",
  },
  {
    to: "/settings",
    iconSrc: settingsIcon,
    activeIcon: settingsActiveIcon,
    alt: "Settings",
    text: "Settings",
  },
];

export const imageSizes = {
  medium: {
    width: 160,
    height: 90,
  },
  large: {
    width: 350,
    height: 200,
  },
  thumbnail: {
    width: 100,
    height: 100,
  },
};

export const weAreInMotionNodes = [
  {
    type: "canRollOver",
    text: "I can roll over!",
    date: "",
    defaultImage: weareinmotion1,
    databaseImage: "",
  },
  {
    type: "canCrouch",
    text: "I can crawl!",
    date: "",
    defaultImage: weareinmotion2,
    databaseImage: "",
  },
  {
    type: "canSit",
    text: "I can sit!",
    date: "",
    defaultImage: weareinmotion3,
    databaseImage: "",
  },
  {
    type: "canWalk",
    text: "I can walk!",
    date: "",
    defaultImage: weareinmotion4,
    databaseImage: "",
  },
];

export const firstBirthdayNode = [
  {
    id: "",
    text: "",
    images: [{}],
  },
];

export const firstHolidayNode = [
  {
    id: "",
    text: "",
    type: "",
    images: [{}],
  },
];

export const holidayData = [
  { name: "Easter", value: "easter" },
  { name: "Christmas", value: "christmas" },
  { name: "Ramadan", value: "ramadan" },
  { name: "Diwali", value: "diwali" },
  { name: "Hanukkah", value: "hanukkah" },
];

export const firstYearData = [
  {
    position: 0,
    label: "1",
    image: null,
    imageFile: null,
    padding: "0 0 0 0",
  },
  {
    position: 1,
    label: "2",
    image: null,
    imageFile: null,
    padding: "0 0 0 0",
  },
  {
    position: 2,
    label: "3",
    image: null,
    imageFile: null,
    padding: "0 200px 0 0",
  },
  {
    position: 3,
    label: "4",
    image: null,
    imageFile: null,
    padding: "0 50px 0 0",
  },
  {
    position: 4,
    label: "5",
    image: null,
    imageFile: null,
    padding: "0 0 0 200px",
  },
  {
    position: 5,
    label: "6",
    image: null,
    imageFile: null,
    padding: "0 0 0 200px",
  },
  {
    position: 6,
    label: "7",
    image: null,
    imageFile: null,
    padding: "0 0 0 0",
  },
  {
    position: 7,
    label: "8",
    image: null,
    imageFile: null,
    padding: "0 0 0 50px",
  },
  {
    position: 8,
    label: "9",
    image: null,
    imageFile: null,
    padding: "0 0 0 100px",
  },
  {
    position: 9,
    label: "10",
    image: null,
    imageFile: null,
    padding: "0 80px 0 0",
  },
  {
    position: 10,
    label: "11",
    image: null,
    imageFile: null,
    padding: "0 150px 0 0",
  },
  {
    position: 11,
    label: "12",
    image: null,
    imageFile: null,
    padding: "0 0px 0 0",
  },
];

export const teethData = [
  {
    position: 0,
    date: "",
    left: "3%",
    bottom: "",
    right: "",
    top: "8%",
    zindex: "1",
    image: tooth2,
    boldedImage: boldtooth2,
  },
  {
    position: 1,
    date: "",
    left: "7%",
    bottom: "",
    right: "",
    top: "27%",
    zindex: "2",
    image: tooth1,
    boldedImage: boldtooth1,
  },
  {
    position: 2,
    date: "",
    left: "14%",
    bottom: "",
    right: "",
    top: "44%",
    zindex: "3",
    image: tooth3,
    boldedImage: boldtooth3,
  },
  {
    position: 3,
    date: "",
    left: "25%",
    bottom: "",
    right: "",
    top: "62%",
    zindex: "4",
    image: tooth4,
    boldedImage: boldtooth4,
  },
  {
    position: 4,
    date: "",
    left: "37%",
    bottom: "",
    right: "",
    top: "70%",
    zindex: "5",
    image: tooth4,
    boldedImage: boldtooth4,
  },
  {
    position: 5,
    date: "",
    left: "",
    bottom: "",
    right: "37%",
    top: "70%",
    zindex: "5",
    image: tooth5,
    boldedImage: boldtooth5,
  },
  {
    position: 6,
    date: "",
    left: "",
    bottom: "",
    right: "25%",
    top: "62%",
    zindex: "4",
    image: tooth5,
    boldedImage: boldtooth5,
  },
  {
    position: 7,
    date: "",
    left: "",
    bottom: "",
    right: "14%",
    top: "44%",
    zindex: "3",
    image: tooth6,
    boldedImage: boldtooth6,
  },
  {
    position: 8,
    date: "",
    left: "",
    bottom: "",
    right: "7%",
    top: "27%",
    zindex: "2",
    image: tooth1,
    boldedImage: boldtooth1,
  },
  {
    position: 9,
    date: "",
    left: "",
    bottom: "",
    right: "3%",
    top: "8%",
    zindex: "1",
    image: tooth2,
    boldedImage: boldtooth2,
  },
  {
    position: 10,
    date: "",
    left: "",
    bottom: "8%",
    right: "3%",
    top: "",
    zindex: "5",
    image: tooth2,
    boldedImage: boldtooth2,
  },
  {
    position: 11,
    date: "",
    left: "",
    bottom: "27%",
    right: "7%",
    top: "",
    zindex: "4",
    image: tooth1,
    boldedImage: boldtooth1,
  },
  {
    position: 12,
    date: "",
    left: "",
    bottom: "44%",
    right: "14%",
    top: "",
    zindex: "3",
    image: tooth6,
    boldedImage: boldtooth6,
  },
  {
    position: 13,
    date: "",
    left: "",
    bottom: "62%",
    right: "25%",
    top: "",
    zindex: "2",
    image: tooth4,
    boldedImage: boldtooth4,
  },
  {
    position: 14,
    date: "",
    left: "",
    bottom: "70%",
    right: "37%",
    top: "",
    zindex: "1",
    image: tooth4,
    boldedImage: boldtooth4,
  },
  {
    position: 15,
    date: "",
    left: "37%",
    bottom: "70%",
    right: "",
    top: "",
    zindex: "1",
    image: tooth5,
    boldedImage: boldtooth5,
  },
  {
    position: 16,
    date: "",
    left: "25%",
    bottom: "62%",
    right: "",
    top: "",
    zindex: "2",
    image: tooth5,
    boldedImage: boldtooth5,
  },
  {
    position: 17,
    date: "",
    left: "14%",
    bottom: "44%",
    right: "",
    top: "",
    zindex: "3",
    image: tooth3,
    boldedImage: boldtooth3,
  },
  {
    position: 18,
    date: "",
    left: "7%",
    bottom: "27%",
    right: "",
    top: "",
    zindex: "4",
    image: tooth1,
    boldedImage: boldtooth1,
  },
  {
    position: 19,
    date: "",
    left: "3%",
    bottom: "8%",
    right: "",
    top: "",
    zindex: "5",
    image: tooth2,
    boldedImage: boldtooth2,
  },
];

export const stripeProductPriceIds = {
  premium: "price_1P0QYPKpqiTwXCAQ2D2isui1",
  medium: "price_1P3mZeKpqiTwXCAQOgyJUMLg",
};

export interface Plan {
  name: string;
  features: string[];
  isActive?: boolean;
  priceId?: string;
}

export interface PlanProps {
  plan: Plan;
  isChecked: boolean;
  subscribedPlan: any;
  upgradeButtonLoading: boolean;
  onCheckboxChange: () => void;
  onUpgradeClick: () => void;
  onCancelClick: () => void;
}

// export const plans: Plan[] = [
//   // {
//   //   name: "Freemium",
//   //   features: [
//   //     "Up to 2 profiles",
//   //     "Up to 2 contributors per profile",
//   //     "Up to 500 mb of media storage",
//   //   ],
//   // },
//   // {
//   //   name: "Medium",
//   //   features: [
//   //     "Up to 5 profiles",
//   //     "Up to 5 contributors per profile",
//   //     "Up to 250 gb of media storage",
//   //   ],
//   //   stripePriceId: stripeProductPriceIds.medium,
//   // },
//   {
//     name: "Premium",
//     features: [
//       "Unlimited profiles and users",
//       "Unlimited contributors per profile",
//       "Up to 1TB of media storage",
//       "Special offers and freebies",
//     ],
//   },
// ];
export const appImages = {
  blankProfilePicture:
    "https://firebasestorage.googleapis.com/v0/b/little-feather-dev.appspot.com/o/app-images%2FblankProfilePicture.jpg?alt=media&token=52a28ce4-3fe5-4374-b2ca-a79983e1cf76",
};
export const userBabyRelationEnum = {
  admin: "admin",
  mother: "mother",
  father: "father",
  grandFather: "grandFather",
  grandMother: "grandMother",
  sister: "sister",
  brother: "brother",
  sibling: "sibling",
} as const;

// Use the enum values to create the invitationRelationOptions
export const invitationRelationOptions = [
  { value: userBabyRelationEnum.father, label: "Father" },
  { value: userBabyRelationEnum.mother, label: "Mother" },
  { value: userBabyRelationEnum.grandFather, label: "Grandfather" },
  { value: userBabyRelationEnum.grandMother, label: "Grandmother" },
  { value: userBabyRelationEnum.sibling, label: "Sibling" },
  { value: userBabyRelationEnum.sister, label: "Sister" },
  { value: userBabyRelationEnum.brother, label: "Brother" },
  { value: userBabyRelationEnum.admin, label: "Admin" },
];
