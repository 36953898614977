import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../helpers/components/Button";
import { OnboardingCard } from "../shared/OnboardingCard";
import bgFeet from "../../images/background_feet.svg";
import {
  CustomPasswordInput,
  CustomTextInput,
} from "../../helpers/components/CustomInputs";
import {
  BackgroundImage,
  Description,
  GoBackButton,
  HeaderIcon,
  Headline,
  WelcomeText,
  ButtonWrapper,
  SubHeadline,
} from "./shared/SharedStyles";
import { useLocation, useNavigate } from "react-router";
import { colors } from "../../styles/style";
import { auth } from "./logic/firebaseConfig";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "@firebase/auth";
import goBackIcon from "../../images/goBackIcon.svg";
import { errorMessages } from "../../common/constants";
import { SmallTextMedium } from "../shared/SharedStyles";

export const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [userAuthCredentials, setUserAuthCredentials] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState<any | null>(false);
  // const [recaptchaVerifier, setRecaptchaVerifier] =
  //   useState<RecaptchaVerifier | null>(null); // Specify the type
  // const [recaptchaCompleted, setRecaptchaCompleted] = useState(false);

  // useEffect(() => {
  //   const verifier = new RecaptchaVerifier(auth, "recaptcha-container", {});

  //   verifier.render().then(() => {
  //     // Add an event listener for reCAPTCHA completion
  //     verifier.verify().then(handleRecaptchaCompletion);
  //   });

  //   setRecaptchaVerifier(verifier);
  // }, []);

  // const handleRecaptchaCompletion = () => {
  //   setRecaptchaCompleted(true);
  // };

  const goBackHandler = () => {
    navigate(-1);
  };
  const location = useLocation();
  const getQueryParams = (url: string) => {
    const params: { [key: string]: string } = {};
    const queryString = url.split("?")[1];

    if (queryString) {
      const keyValuePairs = queryString.split("&");

      keyValuePairs.forEach((pair) => {
        const [key, value] = pair.split("=");
        params[key] = decodeURIComponent(value);
      });
    }
    return params;
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const registeredStatus = queryParams.registered;
    const userEmail = queryParams.email;
    if (userEmail !== undefined) {
      setUserAuthCredentials({
        ...userAuthCredentials,
        email: userEmail,
      });
    }

    if (registeredStatus && registeredStatus === "true") {
      setError(errorMessages.emailVerificationSent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleLogin = async (event: any) => {
    event.preventDefault(event);
    if (loading) return;
    setLoading(true);
    const firebaseAuth = auth;
    const { email, password } = userAuthCredentials;
    let token = "";
    // await signInWithEmailAndPassword(firebaseAuth, email, password)
    //   .then((userCredential: any) => {
    //     token = userCredential.user?.accessToken;
    //     localStorage.setItem("little-feather-token", token);
    //     const newUser = searchParams.get("registered");
    //     if (newUser && newUser === "true") {
    //       navigate("/welcome");
    //     } else {
    //       window.location.reload();
    //     }
    //   })
    //   .catch(() => {});

    try {
      await signInWithEmailAndPassword(firebaseAuth, email, password);
      const localUser = await firebaseAuth.currentUser;
      setUser(localUser);
      if (localUser) {
        if (!localUser.emailVerified) {
          setError(errorMessages.verifyEmailBeforeLogin);
          setLoading(false);
        } else {
          // if (recaptchaVerifier && recaptchaCompleted) {
          token = await localUser.getIdToken();
          localStorage.setItem("little-feather-token", token);
          window.location.reload();
          // } else {
          //   setError(errorMessages.completeReCaptcha);
          // }
        }
      }
    } catch (error: any) {
      // if (error.code === "auth/multi-factor-auth-required") {
      // const resolver = getMultiFactorResolver(auth, error);

      // const phoneInfoOptions = {
      //   multiFactorHint: resolver.hints[0],
      //   session: resolver.session,
      // };

      // const phoneAuthProvider = new PhoneAuthProvider(auth);
      // if (recaptchaVerifier && recaptchaCompleted) {
      //   return phoneAuthProvider
      //     .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      //     .then(function (verificationId) {
      //       // Ask user for the SMS verification code. Then:
      //       const verificationCode = prompt("Enter SMS Verification Code:");

      //       if (verificationCode !== null) {
      //         const cred = PhoneAuthProvider.credential(
      //           verificationId,
      //           verificationCode
      //         );

      //         const multiFactorAssertion =
      //           PhoneMultiFactorGenerator.assertion(cred);
      //         // Complete sign-in.

      //         return resolver.resolveSignIn(multiFactorAssertion);
      //       } else {
      //         setError(errorMessages.wrongCode);
      //         // Handle the cancellation or provide appropriate feedback to the user.
      //       }
      //     })
      //     .then(async function (userCredential) {
      //       // User successfully signed in with the second factor phone number.
      //       if (userCredential && userCredential.user) {
      //         token = await userCredential.user.getIdToken();
      //         localStorage.setItem("little-feather-token", token);
      //         window.location.reload();
      //       }
      //     })
      //     .catch(error);
      // } else {
      //   setError(errorMessages.completeReCaptcha);
      // }
      // } else {
      if (error.code === "auth/invalid-email") {
        setError(errorMessages.invalidEmailError);
      } else if (error.code === "auth/invalid-credential") {
        setError(errorMessages.invalidCredentialError);
      } else if (error.code === "auth/wrong-password") {
        setError(errorMessages.wrongPasswordError);
      } else if (error.code === "auth/too-many-requests") {
        setError(errorMessages.toManyLoginRequests);
      } else {
        setError(errorMessages.genericError);
      }
      setLoading(false);
    }
  };

  const resendVerificationEmail = async (e: any) => {
    e.preventDefault();
    if (user) {
      try {
        await sendEmailVerification(user);
      } catch (error) {
        setError(errorMessages.tooManyRequests);
      }
    }
  };

  return (
    <OnboardingCard>
      <HeaderIcon src={goBackIcon} onClick={goBackHandler} />
      <BackgroundImage $right="5%" $top="3%" alt="Frame" src={bgFeet} />
      <MainContainer>
        <WelcomeText>
          <SubHeadline>Welcome back to</SubHeadline>
          <Headline>Little Feather</Headline>
          <Description $padding="0px 110px 20px 0px">
            Please enter your details selected upon sign up.
          </Description>
        </WelcomeText>
        <FormWrapper>
          <CustomTextInput
            label="Email"
            value={userAuthCredentials.email}
            placeholder="Enter email"
            onChange={(e) =>
              setUserAuthCredentials({
                ...userAuthCredentials,
                email: e.target.value,
              })
            }
          />
          <CustomPasswordInput
            label="Password"
            value={userAuthCredentials.password}
            placeholder="Enter password"
            onChange={(e) =>
              setUserAuthCredentials({
                ...userAuthCredentials,
                password: e.target.value,
              })
            }
          />
          {/* <div id="recaptcha-container"></div> */}
          <RegisterNowText>
            Don’t have account?{" "}
            <b onClick={() => navigate("/register")}>Register Now</b>
            <br /> <br />
            <b onClick={() => navigate("/reset-password")}>Forgot Password?</b>
          </RegisterNowText>
          <ButtonWrapper>
            {error && (
              <Description
                $padding=""
                style={{
                  color:
                    error === errorMessages.emailVerificationSent
                      ? colors.darkBrown
                      : colors.red,
                }}
              >
                {error}
              </Description>
            )}

            {error === errorMessages.verifyEmailBeforeLogin && (
              <Description
                onClick={resendVerificationEmail}
                style={{
                  color: colors.warmthBrown,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Resend verification email
              </Description>
            )}
            <Button
              $button_type="colored"
              onClick={(e) => handleLogin(e)}
              disabled={
                !userAuthCredentials.email ||
                !userAuthCredentials.password ||
                loading
              }
              loading={loading}
            >
              Log In
            </Button>
            <GoBackButton onClick={goBackHandler}>Go Back</GoBackButton>
          </ButtonWrapper>
        </FormWrapper>
      </MainContainer>
    </OnboardingCard>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 90px 0px 0px 0;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  margin-top: 60px;
`;

const RegisterNowText = styled.p`
  color: ${colors.darkBrown};
  ${SmallTextMedium};
  padding-top: 25px;
`;
