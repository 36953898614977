import React from "react";
import { MilestonesCard } from "./common/MilestonesCard";
import { MilestonesHeader } from "./common/MilestonesHeader";
import { MilestoneList } from "../feed/common/MilestoneList";
import { NavigationMenu } from "../shared/NavigationMenu";
import { AllMilestones } from "./common/AllMilestones";
import { Outlet } from "react-router-dom";
import { useBabyContext } from "../../context/BabyContext";
import { NoBabySelectedErrorText } from "../profile/ProfilePage";

export const MilestonesPage = () => {
  const { selectedBaby } = useBabyContext();
  return (
    <MilestonesCard>
      <MilestonesHeader />
      {selectedBaby ? (
        <>
          <MilestoneList />
          <AllMilestones />
        </>
      ) : (
        <NoBabySelectedErrorText />
      )}
      <NavigationMenu />
      <Outlet />
    </MilestonesCard>
  );
};
