import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ListItemImage } from "../../../feed/common/shared/ListItemImage";
import editIcon from "../../../../images/editIcon.svg";
import deleteIcon from "../../../../images/deleteIcon.svg";
import { colors } from "../../../../styles/style";
import { useBabyContext } from "../../../../context/BabyContext";
import { useNavigate } from "react-router-dom";
import {
  calculateAgeFromTimestamp,
  capitalizeFirstLetter,
  getResizedImageUrl,
} from "../../../../common/helpers";
import {
  FlexRowBetweenCenter,
  SmallIcon,
  SmallText,
  SmallestText,
} from "../../../shared/SharedStyles";
import { imageSizes } from "../../../../common/constants";
import { removeBabyContributor } from "../../../../api";
import { AuthContext } from "../../../../context/AuthContext";
import { DeleteAccountConfirmation } from "../DeleteAccountConfirmation";

interface BabyBadgeProps {
  itemData: {
    name: string;
    gender?: string;
    age?: number;
    descriptionText?: string;
    image: string;
    id: string;
    userId?: string;
    babyId?: string;
    deleteAllowed?: boolean;
    email?: string;
    entity?: "invitation" | "collaborator";
  };
  onRefresh?: () => void;
  parentComponent: "baby-info" | "profile-contributors";
}

export const ListItemWithImage: React.FC<BabyBadgeProps> = ({
  itemData,
  parentComponent,
  onRefresh,
}) => {
  const {
    name,
    gender,
    age,
    image,
    id,
    descriptionText,
    userId,
    babyId,
    deleteAllowed,
    email,
    entity,
  } = itemData;
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { updateSelectedBaby } = useBabyContext();
  const { authUser } = authContext;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);

  const handleOnClick = async () => {
    if (parentComponent === "baby-info") {
      await updateSelectedBaby(id);
      navigate("/baby-info");
    }
  };
  const handleRemoveContributor = async () => {
    setLoadingAction(true);
    try {
      await removeBabyContributor(babyId as string, userId as string);
      onRefresh && onRefresh();
    } catch (error) {
      console.error("Error removing contributor:", error);
    }
    setLoadingAction(false);
  };

  return (
    <Container
      onClick={handleOnClick}
      style={{
        backgroundColor:
          entity && entity === "invitation"
            ? colors.filledWhiteBackgroundColor
            : colors.softGrey,
      }}
    >
      <ListItemImage
        alt={parentComponent === "baby-info" ? "Baby Image" : "Profile Image"}
        src={getResizedImageUrl(image, imageSizes.thumbnail)}
        onError={(e) => {
          e.currentTarget.src = image;
        }}
      />
      <DetailsAndIcon>
        <Details>
          <SmallText
            $color={colors.darkBrown}
            style={{ fontSize: name?.length > 12 ? "10px" : "14px" }}
          >
            {name ?? "Removed User"}
          </SmallText>
          {parentComponent === "baby-info" ? (
            <>
              {age && gender ? (
                <DescriptionText>{`${capitalizeFirstLetter(
                  gender
                )}, ${calculateAgeFromTimestamp(age)}`}</DescriptionText>
              ) : (
                <DescriptionText>{`${capitalizeFirstLetter(
                  gender ?? ""
                )}`}</DescriptionText>
              )}
            </>
          ) : (
            descriptionText && (
              <>
                {email && <DescriptionText>{email}</DescriptionText>}
                <DescriptionText>{`${capitalizeFirstLetter(
                  descriptionText
                )}`}</DescriptionText>
              </>
            )
          )}
        </Details>
        {
          // Only show edit icon for baby-info
          parentComponent === "baby-info" ? (
            <SmallIcon src={editIcon} alt="Edit Icon" />
          ) : (
            deleteAllowed &&
            userId !== authUser.id && (
              <SmallIcon
                src={deleteIcon}
                alt="Remove Icon"
                onClick={() => setShowConfirmationModal(true)}
              />
            )
          )
        }
        {showConfirmationModal && (
          <DeleteAccountConfirmation
            showModal={showConfirmationModal}
            handleSetShowModal={() => setShowConfirmationModal(false)}
            title="Just Checking!"
            handleActionClick={handleRemoveContributor}
            loading={loadingAction}
            paragraphText="This will delete the selected user, and they’ll no longer have access to the baby."
            headerText="Are you sure you want to go ahead?"
          />
        )}
      </DetailsAndIcon>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 32px;
  /* background: ${colors.softGrey}; */
  padding: 8px 12px;
  gap: 12px;
`;

const DetailsAndIcon = styled.div`
  flex-grow: 1;
  ${FlexRowBetweenCenter}
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DescriptionText = styled.p`
  color: ${colors.softBrown};
  ${SmallestText};
  letter-spacing: -0.21px;
`;
