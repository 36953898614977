import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { BackgroundImage } from "../../authentication/shared/SharedStyles";
import treeImage from "../../../images/newTreeImage.png";
import { colors } from "../../../styles/style";
import goBackIcon from "../../../images/goBackIcon.svg";
import Button from "../../../helpers/components/Button";
import addIcon from "../../../images/addIcon.svg";
import editIcon from "../../../images/editIcon.svg";
import {
  BigHeaderText,
  FlexRowBetweenCenter,
  MediumIcon,
  SmallIcon,
  SmallestText,
} from "../../shared/SharedStyles";
import { useBabyContext } from "../../../context/BabyContext";
import { useNavigate } from "react-router-dom";
import { getFamilyTreeByBaby } from "../../../api";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { AddNewMemberModal } from "./common/AddNewMemberModal";
import { getResizedImageUrl } from "../../../common/helpers";
import { imageSizes } from "../../../common/constants";
import {
  MilestoneButtonWrapper,
  MilestoneSelectedImage,
  MilestoneWrapper,
} from "../common/SharedComponents";
import { AuthContext } from "../../../context/AuthContext";
import { SharedButton } from "../../settings/common/shared/SharedButton";
import { ImageGalleryModal } from "../../feed/common/ImageGalleryModal";

export const FamilyTree = () => {
  const navigate = useNavigate();
  const { hasActivePayment } = React.useContext(AuthContext);
  const { selectedBaby } = useBabyContext();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState({
    initialLoading: false,
    addMemberLoading: false,
  });
  const [editMode, setEditMode] = useState(false);
  const [showImageGallery, setShowImageGallery] = useState(null);

  const canEdit =
    selectedBaby.loggedInUserRelation === 0 ||
    selectedBaby.loggedInUserRelation?.toString().toLowerCase() === "admin";

  const relationshipData = [
    {
      position: 0,
      label: "",
      image: null,
      imageFile: null,
    },
    {
      position: 1,
      label: "",
      image: null,
      imageFile: null,
    },
    {
      position: 2,
      label: "",
      image: null,
      imageFile: null,
    },
    {
      position: 3,
      label: "",
      image: null,
      imageFile: null,
    },
    {
      position: 4,
      label: "",
      image: null,
      imageFile: null,
    },
    {
      position: 5,
      label: "",
      image: null,
      imageFile: null,
    },
    {
      position: 6,
      label: "",
      image: null,
      imageFile: null,
    },
    {
      position: 7,
      label: "",
      image: null,
      imageFile: null,
    },
    // Add two new positions for relatives
    {
      position: 8,
      label: "",
      image: null,
      imageFile: null,
    },
    {
      position: 9,
      label: "",
      image: null,
      imageFile: null,
    },
    {
      position: 10,
      label: selectedBaby?.name || null,
      image: selectedBaby?.profile_picture || null,
      imageFile: null,
    },
  ];

  const [familyMembers, setFamilyMembers] = useState<any>([]);
  const [memberToAddOrUpdate, setMemberToAddOrUpdate] = useState<any>(null);

  const goBackHandler = () => {
    navigate(-1);
  };

  const fetchFamilyMembers = async () => {
    try {
      setLoading({ ...loading, initialLoading: true });
      const { data } = await getFamilyTreeByBaby(selectedBaby.id);
      setFamilyMembers(data);
    } catch (error) {
      console.error(error);
    }
    setLoading({ ...loading, initialLoading: false });
  };

  useEffect(() => {
    fetchFamilyMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRowWrapper = (start: number, end: number | undefined) => {
    return (
      <FirstRowWrapper>
        {relationshipData
          .slice(start, end)
          .map((member: any, index: number) => {
            let familyMember = familyMembers.find(
              (f: any) => f.position === member.position
            );
            if (!familyMember) {
              familyMember = member;
            }
            if (member.position === 10) {
              familyMember = member;
            }
            return (
              <ImageInputContainer
                disabled={!hasActivePayment && familyMember?.image === null}
                key={index + start}
                onClick={() => {
                  if (canEdit && editMode) {
                    setMemberToAddOrUpdate(familyMember);
                    setShowModal(member.position < 10 ? true : false);
                  } else {
                    setShowImageGallery(
                      familyMember.image || familyMember.imageUrl
                    );
                  }
                }}
              >
                {familyMember?.image ||
                familyMember?.imageFile ||
                familyMember?.imageUrl ? (
                  <MilestoneSelectedImage
                    src={
                      familyMember?.imageFile &&
                      familyMember.isUpdated &&
                      familyMember.position < 10
                        ? URL.createObjectURL(familyMember.imageFile)
                        : getResizedImageUrl(
                            familyMember?.image,
                            imageSizes.thumbnail
                          ) ??
                          getResizedImageUrl(
                            familyMember?.imageUrl,
                            imageSizes.thumbnail
                          )
                    }
                    onError={(e) => {
                      e.currentTarget.src = familyMember?.image;
                    }}
                    alt={`${familyMember.label}'s Image`}
                  />
                ) : (
                  <SmallIcon src={addIcon} />
                )}
                {familyMember?.label && (
                  <NameOfRelationship
                    style={{
                      fontSize: "8px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                      maxWidth: "100px",
                    }}
                  >
                    {familyMember.label}
                  </NameOfRelationship>
                )}
              </ImageInputContainer>
            );
          })}
      </FirstRowWrapper>
    );
  };

  if (loading.initialLoading) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      <Container editMode={editMode}>
        <BackgroundImage
          $blend_mode=""
          $position="absolute"
          $left="50%"
          $top="45%"
          $transform="translate(-50%, -50%)"
          $width="390px"
          alt="Frame"
          src={treeImage}
        />
        <HeaderContainer>
          <MediumIcon src={goBackIcon} onClick={goBackHandler} />
          <BigHeaderText>My Family Tree</BigHeaderText>
          <div></div>
        </HeaderContainer>
        <MilestoneWrapper>
          <NodeWrapper>
            {renderRowWrapper(0, 4)}
            <StyledRow2>{renderRowWrapper(4, 8)}</StyledRow2>
            {renderRowWrapper(8, 10)} {/* New row to display 4 elements */}
            {renderRowWrapper(-1, undefined)}
          </NodeWrapper>
        </MilestoneWrapper>
        <MilestoneButtonWrapper>
          <SharedButton
            iconSrc={editIcon}
            buttonText={!editMode && canEdit ? "Edit Mode" : "View Mode"}
            $margin="36px 0 0 0"
            onClick={() => setEditMode(!editMode)}
            $disabled={!canEdit}
          />
          <Button $button_type="primary" onClick={goBackHandler}>
            Go Back
          </Button>
        </MilestoneButtonWrapper>
      </Container>
      {showModal && (
        <AddNewMemberModal
          showModal={showModal}
          handleSetShowModal={() => setShowModal(!showModal)}
          memberToAddOrUpdate={memberToAddOrUpdate}
          setMemberToAddOrUpdate={setMemberToAddOrUpdate}
          handleRefreshMembers={fetchFamilyMembers}
          parentComponent="familyTree"
        />
      )}
      {showImageGallery && (
        <ImageGalleryModal
          images={[showImageGallery]}
          onClose={() => setShowImageGallery(null)}
        />
      )}
    </React.Fragment>
  );
};

const Container = styled.div<{ editMode: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  padding-top: 40px;
  background-color: ${(props) =>
    props.editMode ? "#f5f5dcc" : colors.bgWhite};
`;

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const ImageInputContainer = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  border: 2px solid ${colors.white};
  border-radius: 50%;
  cursor: pointer;
  background-color: ${colors.lightPeach};
  box-shadow: 0px 1.348px 14.149px 3.369px rgba(153, 158, 216, 0.2);
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`;

const NameOfRelationship = styled.div`
  position: absolute;
  padding: 6px 17px 5px 18px;
  bottom: -25%;
  background-color: ${colors.warmthPink};
  border-radius: 40px;
  color: ${colors.white};
  ${SmallestText};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 150px;
  text-align: center;
`;

const FirstRowWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const NodeWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

const StyledRow2 = styled(FirstRowWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 40px;
`;
