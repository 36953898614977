import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export const uploadImageToStorage = async (
  imageFile: File,
  folder = "images/baby/"
) => {
  const imageFileName = uuidv4();

  // Create a storage reference with the generated filename
  const storage = getStorage();
  const imageContainerUrl = folder + imageFileName;
  const imageRef = ref(storage, imageContainerUrl);
  // Upload the image to Firebase Storage
  await uploadBytes(imageRef, imageFile);

  // Get the download URL of the uploaded image
  const imageUrl = await getDownloadURL(imageRef);
  return { imageContainerUrl, imageUrl };
};

export const calculateAgeFromTimestamp = (birthTimestamp: number): string => {
  const birthDate = new Date(birthTimestamp);
  const currentDate = new Date();
  const ageInMonths =
    (currentDate.getFullYear() - birthDate.getFullYear()) * 12 +
    (currentDate.getMonth() - birthDate.getMonth());

  const ageInYears = Math.floor(ageInMonths / 12);
  const remainingMonths = ageInMonths % 12;

  if (ageInYears > 0) {
    return `${ageInYears} ${ageInYears === 1 ? "year old" : "years old"}`;
  } else {
    return `${remainingMonths} ${
      remainingMonths === 1 ? "month old" : "months old"
    }`;
  }
};

export const capitalizeFirstLetter = (inputString: string) => {
  const result = inputString[0]?.toUpperCase() + inputString?.slice(1);

  return result;
};
const dateWithMonthNameFormat = "MMMM D, yyyy";

export const formatLongDate = (date: any) => {
  // date = new Date(date * 1000);
  return moment(date).format(dateWithMonthNameFormat);
};

export const formatTimestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000); // Convert to milliseconds

  const month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

export const formatTimestampToDefaultDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  const year = date.getFullYear() % 100;
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const formattedYear = year.toString().padStart(2, "0");
  const formattedMonth = month.toString().padStart(2, "0");
  const formattedDay = day.toString().padStart(2, "0");

  return `${formattedDay}.${formattedMonth}.${formattedYear}`;
};
export const formatDateForInputValue = (date: any) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
export const getMonthText = (month: number) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Ensure the month value is within a valid range
  const adjustedMonth = Math.max(1, Math.min(12, month));

  return months[adjustedMonth - 1]; // Adjusting to 0-based index
};

export function getResizedImageUrl(
  originalUrl: string,
  imageSizes: { width: number; height: number }
): string {
  const pathAndRest = originalUrl.split("%2F");
  const idAndRest = pathAndRest.pop()!.split("?");
  const id = idAndRest[0];
  const path = pathAndRest.join("%2F");
  const rest = idAndRest.slice(1).join("?");
  const resizedUrl =
    path +
    "%2Fresized%2F" +
    id +
    "_" +
    imageSizes.width +
    "x" +
    imageSizes.height +
    "?" +
    rest;

  return resizedUrl;
}

export async function getCroppedImg(
  imageSrc: any,
  pixelCrop: any,
  rotation = 0,
  imageFileName?: string
) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx: any = canvas.getContext("2d");
  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
  canvas.width = safeArea;
  canvas.height = safeArea;
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  return new Promise<File>((resolve) => {
    canvas.toBlob((blob: any) => {
      const ext = imageFileName ? imageFileName.split(".")[1] : "";
      const file = new File(
        [blob],
        imageFileName
          ? imageFileName.replace(`.${ext}`, "_cropped") + ".jpeg"
          : "",
        { type: "image/jpeg" }
      );
      resolve(file);
    }, "image/jpeg");
  });
}

// export async function getCroppedImgNew(
//   imageSrc: any,
//   pixelCrop: any,
//   rotation = 0,
//   imageFileName?: string
// ) {
//   const image: any = await createImage(imageSrc);

//   const canvas = document.createElement("canvas");
//   canvas.width = pixelCrop.width;
//   canvas.height = pixelCrop.height;
//   const ctx: any = canvas.getContext("2d");
//   ctx.drawImage(
//     image,
//     pixelCrop.x,
//     pixelCrop.y,
//     pixelCrop.width,
//     pixelCrop.height,
//     0,
//     0,
//     pixelCrop.width,
//     pixelCrop.height
//   );
//   return canvas.toDataURL("image/jpeg");
// }
export async function getCroppedImgNew(
  imageSrc: any,
  pixelCrop: any,
  rotation = 0,
  imageFileName?: string
) {
  const image: any = await createImage(imageSrc);

  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx: any = canvas.getContext("2d");
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob: any) => {
      if (!blob) {
        reject(new Error("Failed to create blob."));
        return;
      }

      if (imageFileName) {
        const file = new File([blob], imageFileName, { type: "image/jpeg" });
        resolve(file);
      } else {
        resolve(blob);
      }
    }, "image/jpeg");
  });
}

const createImage = (url: any) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

function getRadianAngle(degreeValue: any) {
  return (degreeValue * Math.PI) / 180;
}
export const sortMeasurementsByDateAsc = (data: any) => {
  const sortedData = data.sort((a: any, b: any) => a.created_at - b.created_at);
  return sortedData[0];
};

export const validateEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};
