import React, { useRef, useState } from "react";
import styled from "styled-components";
import goBackIcon from "../../../../images/goBackIcon.svg";
import Button from "../../../../helpers/components/Button";
import {
  BigHeaderText,
  FlexRowBetweenCenter,
  MediumIcon,
  ModalOverlay,
  SmallIcon,
} from "../../../shared/SharedStyles";
import { EditModal } from "../../../shared/EditModal";
import { SharedButton } from "../../../settings/common/shared/SharedButton";
import cameraIcon from "../../../../images/cameraIconBlack.svg";
import penIcon from "../../../../images/penIconBlack.svg";
import deleteIcon from "../../../../images/deleteIcon.svg";
import remove_image_icon from "../../../../images/remove-image-icon.svg";
import { CustomTextInput } from "../../../../helpers/components/CustomInputs";
import { uploadImageToStorage } from "../../../../common/helpers";
import {
  addFamilyTreeMember,
  deleteFamilyTreeMember,
  updateFamilyTreeMember,
} from "../../../../api";
import { useBabyContext } from "../../../../context/BabyContext";
import { ImageCropper } from "./CropImage";

interface AddNewMemberModalProps {
  showModal: boolean;
  handleSetShowModal: React.Dispatch<React.SetStateAction<any>>;
  memberToAddOrUpdate: any; // Adjust the type as needed
  setMemberToAddOrUpdate: React.Dispatch<React.SetStateAction<any>>;
  handleRefreshMembers: () => void;
  parentComponent?: "familyTree" | "milestone";
}

export const AddNewMemberModal: React.FC<AddNewMemberModalProps> = ({
  showModal,
  handleSetShowModal,
  memberToAddOrUpdate,
  setMemberToAddOrUpdate,
  handleRefreshMembers,
  parentComponent,
}) => {
  const { selectedBabyId, selectedBaby } = useBabyContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteFamilyMemberLoading, setDeleteFamilyMemberLoading] =
    useState<boolean>(false);

  const [isEditing, setIsEditing] = useState({ label: false, image: false });
  const selectedImageInputRef = useRef<HTMLInputElement>(null);
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [selectedPosition, setSelectedPosition] = useState<number | null>(null);

  const isBabyAdmin =
    selectedBaby.loggedInUserRelation === 0 ||
    selectedBaby.loggedInUserRelation?.toString().toLowerCase() === "admin";

  const canDeleteFamilyTreeMember =
    parentComponent === "familyTree" && isBabyAdmin && memberToAddOrUpdate?.id;

  const handleAddPhoto = () => {
    selectedImageInputRef.current?.click();
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedImageFile = e.target.files?.[0];
    setSelectedPosition(memberToAddOrUpdate.position);
    setShowCropperModal(true);
    setUploadedFile(selectedImageFile);
    if (selectedImageFile) {
      setMemberToAddOrUpdate({
        ...memberToAddOrUpdate,
        imageFile: selectedImageFile,
        isUpdated: !memberToAddOrUpdate.isUpdated
          ? true
          : memberToAddOrUpdate.isUpdated,
      });
    }
  };
  async function uploadMemberImage(
    memberToAddOrUpdate: any,
    setMemberToAddOrUpdate: React.Dispatch<any>
  ) {
    const uploadedImage = await uploadImageToStorage(
      uploadedFile,
      "images/milestones/family-tree/"
    );
    setMemberToAddOrUpdate({
      ...memberToAddOrUpdate,
      image: uploadedImage.imageUrl,
    });
    return uploadedImage.imageUrl;
  }

  const handleSubmitMember = async () => {
    setLoading(true);
    const memberToChange = memberToAddOrUpdate;
    if (memberToAddOrUpdate?.id) {
      //patch
      if (memberToAddOrUpdate?.imageFile) {
        const uploadedImageUrl = await uploadMemberImage(
          memberToAddOrUpdate,
          setMemberToAddOrUpdate
        );
        memberToChange.image = uploadedImageUrl;
      }
      await updateFamilyTreeMember(memberToAddOrUpdate?.baby_id, {
        image: memberToChange.image,
        baby_id: memberToChange.baby_id,
        position: memberToChange.position,
        label: memberToChange.label,
      })
        .then((res) => {
          handleRefreshMembers();
          handleSetShowModal(false);
        })
        .catch(() => {});
    } else {
      //post new
      if (memberToAddOrUpdate?.imageFile) {
        const uploadedImageUrl = await uploadMemberImage(
          memberToAddOrUpdate,
          setMemberToAddOrUpdate
        );
        memberToChange.image = uploadedImageUrl;
      }
      await addFamilyTreeMember({
        baby_id: selectedBabyId,
        position: memberToChange.position,
        image: memberToChange.image,
        label: memberToChange.label,
      })
        .then((res) => {
          handleRefreshMembers();
          handleSetShowModal(false);
        })
        .catch((err) => {});
    }
    setLoading(false);
  };

  const croppedImageActionFinished = async (croppedImage: any) => {
    setUploadedFile(croppedImage);
    setShowCropperModal(false);
    if (selectedPosition === null) return;
  };

  const handleDeleteFamilyTreeMember = async () => {
    try {
      setDeleteFamilyMemberLoading(true);
      await deleteFamilyTreeMember(
        selectedBabyId,
        memberToAddOrUpdate.position
      );
      handleRefreshMembers();
      handleSetShowModal(false);
    } catch (error) {
      console.error(error);
    }
    setDeleteFamilyMemberLoading(false);
  };
  return (
    <React.Fragment>
      <EditModal isOpen={showModal} onClose={() => handleSetShowModal(false)}>
        <HeaderContainer>
          <MediumIcon src={goBackIcon} onClick={handleSetShowModal} />
          <BigHeaderText>
            {memberToAddOrUpdate?.label
              ? "Update Tree Entryy"
              : "New Tree Entry"}
          </BigHeaderText>
          <div></div>
        </HeaderContainer>
        <ButtonsWrapper>
          {memberToAddOrUpdate?.imageFile || memberToAddOrUpdate?.image ? (
            <SelectedInputImageContainer>
              <SelectedInputImage
                src={
                  uploadedFile
                    ? URL.createObjectURL(uploadedFile)
                    : memberToAddOrUpdate?.image
                }
                alt="Selected"
              />
              <SmallIcon
                src={remove_image_icon}
                alt="Remove"
                onClick={() => {
                  setMemberToAddOrUpdate({
                    ...memberToAddOrUpdate,
                    imageFile: null,
                    image: null,
                  });
                }}
              />
            </SelectedInputImageContainer>
          ) : (
            <SharedButton
              iconSrc={cameraIcon}
              buttonText="Add a photo"
              onClick={handleAddPhoto}
            />
          )}
          {isEditing.label || memberToAddOrUpdate?.label ? (
            <CustomTextInput
              label=""
              placeholder="Enter label"
              value={memberToAddOrUpdate?.label}
              onChange={(e) =>
                setMemberToAddOrUpdate({
                  ...memberToAddOrUpdate,
                  label: e.target.value,
                  isUpdated: !memberToAddOrUpdate.isUpdated
                    ? true
                    : memberToAddOrUpdate.isUpdated,
                })
              }
            />
          ) : (
            <SharedButton
              iconSrc={penIcon}
              buttonText={"Write a label"}
              onClick={() => setIsEditing({ ...isEditing, label: true })}
            />
          )}
          {canDeleteFamilyTreeMember && (
            <SharedButton
              iconSrc={deleteIcon}
              buttonText={"Remove"}
              onClick={() => handleDeleteFamilyTreeMember()}
              $disabled={
                deleteFamilyMemberLoading || !canDeleteFamilyTreeMember
              }
              loading={deleteFamilyMemberLoading}
            />
          )}
          {(isEditing.label || memberToAddOrUpdate?.label) && (
            <SharedButton
              iconSrc={penIcon}
              buttonText={"Done"}
              onClick={handleSubmitMember}
              $disabled={
                loading ||
                !memberToAddOrUpdate?.label ||
                (!memberToAddOrUpdate?.imageFile && !memberToAddOrUpdate?.image)
              }
              loading={loading}
            />
          )}
          <Button $button_type="colored" onClick={handleSetShowModal}>
            Cancel
          </Button>
        </ButtonsWrapper>

        <ImageInput
          type="file"
          accept="image/*"
          ref={selectedImageInputRef}
          onChange={handleImageChange}
        />
      </EditModal>
      {showCropperModal && (
        <ModalOverlay>
          <ImageCropper
            image={uploadedFile}
            croppedImageAction={croppedImageActionFinished}
            closeModal={() => {
              setShowCropperModal(!showCropperModal);
              setUploadedFile(null);
              setMemberToAddOrUpdate({
                ...memberToAddOrUpdate,
                imageFile: null,
                image: null,
              });
            }}
          />
        </ModalOverlay>
      )}
    </React.Fragment>
  );
};

const ImageInput = styled.input`
  display: none;
`;
const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const SelectedInputImage = styled.img`
  width: 144px;
  height: 78px;
  flex-shrink: 0;
  border-radius: 12px;
`;
const SelectedInputImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  border-radius: 16px;
  border: 1px solid #d9dcdf;
  padding: 8px;
`;
