import React from "react";
import styled from "styled-components";
import Button from "../../helpers/components/Button";
import bgImageFlower from "../../images/bg_image_flower.svg";
import { BackgroundImage, ButtonWrapper } from "./shared/SharedStyles";
import { OnboardingCard } from "../shared/OnboardingCard";
import { useNavigate, useLocation } from "react-router";
import { colors } from "../../styles/style";
import { BigText, MediumText, SmallTextMedium } from "../shared/SharedStyles";

export const FeedbackPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const propsData = location.state;

  return (
    <OnboardingCard $margin="0">
      <BackgroundImage
        $position="fixed"
        $left="50%"
        $bottom="-14%"
        $transform="translate(-50%)"
        $width="390px"
        alt="Frame"
        src={bgImageFlower}
      />
      <MainContainer>
        {propsData.image && (
          <AdditionalImage src={propsData.image} alt="Additional Image" />
        )}
        <ContentContainer>
          <MediumText $margin_top="60px">
            <BigText
              $color={propsData.success ? colors.darkBrown : colors.softOrange}
              $padding_bottom="32px"
            >
              {propsData.headerText}
            </BigText>
            <Paragraph>{propsData.bodyText}</Paragraph>
          </MediumText>

          <ButtonWrapper>
            <Button
              $button_type="colored"
              onClick={() => navigate(propsData.navigateTo)}
            >
              {propsData.buttonText}
            </Button>
          </ButtonWrapper>
        </ContentContainer>
      </MainContainer>
    </OnboardingCard>
  );
};
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  padding-bottom: 90px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  width: 100%;
  padding: 0px 40px;
`;

const AdditionalImage = styled.img`
  width: 100%;
`;

const Paragraph = styled.p`
  color: ${colors.warmthBrown};
  ${SmallTextMedium};
`;
