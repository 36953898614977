import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/style";
import {
  HeaderText,
  SmallIcon,
  SmallText,
  SmallTextSlim,
} from "../../shared/SharedStyles";
import editIcon from "../../../images/editIcon.svg";
import { AddOrUpdateInformation } from "./AddOrUpdateInformation";

export interface IDetails {
  title: string;
  value: ReactNode;
}

interface InformationSectionProps {
  title: string;
  data: IDetails[];
  showEditIcon?: boolean;
}

export const InformationSection: React.FC<InformationSectionProps> = ({
  title,
  data,
  showEditIcon = true,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Container>
        <HeaderWrapper>
          <HeaderText>{title}</HeaderText>
          {showEditIcon && (
            <SmallIcon
              src={editIcon}
              alt="Edit"
              onClick={() => setShowModal(true)}
            />
          )}
        </HeaderWrapper>
        <DetailsWrapper>
          {data.map((item, index) => (
            <StyledDetailsRow key={index}>
              <SmallTextSlim color={colors.warmthBrown}>
                {item.title}
              </SmallTextSlim>
              <SmallText color={colors.darkBrown}>{item.value}</SmallText>
            </StyledDetailsRow>
          ))}
        </DetailsWrapper>
      </Container>
      {showModal && (
        <AddOrUpdateInformation
          showModal={showModal}
          handleSetShowModal={() => setShowModal(!showModal)}
          handleRefreshMembers={() => {}}
          title={title}
          data={data}
        />
      )}
    </>
  );
};

interface ContainerProps {
  children: ReactNode;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 36px 0 0 0;
  gap: 12px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

interface DetailsRowProps {
  children: ReactNode;
}

const StyledDetailsRow = styled.div<DetailsRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${colors.borderWhite};
`;
