import styled from "styled-components";
import { colors } from "../../../styles/style";

export const MilestoneWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MilestoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  padding-top: 40px;
  background-color: ${colors.bgWhite};
  overflow-y: scroll;
`;

export const MilestoneButtonWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  padding: 0 40px 20px 40px;
  z-index: 1;
`;

export const MilestoneClickerScriptText = styled.h3`
  font-family: "Clicker Script";
  font-size: 40px;
  font-weight: 400;
  line-height: 52px;
  text-align: center;
  color: ${colors.softBrown};
`;

export const MilestoneSelectedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;
