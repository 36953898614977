import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { AuthenticationPage } from "../pages/authentication/AuthenticationPage";
import { Register } from "../pages/authentication/RegisterPage";
import { Login } from "../pages/authentication/LoginPage";
import { FeedbackPage } from "../pages/authentication/FeedbackPage";
import { AddBabyForm } from "../pages/baby/AddBabyPage";
import { BabyInfo } from "../pages/baby/BirthCertificatePage";
import { FeedPage } from "../pages/feed/FeedPage";
import { MilestonesPage } from "../pages/miletsones/MilestonesPage";
import { SettingsPage } from "../pages/settings/SettingsPage";
import { AuthContext } from "../context/AuthContext";
import LoadingSpinner from "../common/LoadingSpinner";
import { ProfilePage } from "../pages/profile/ProfilePage";
import { FamilyTree } from "../pages/miletsones/components/FamilyTreePage";
import { MemberAddedPage } from "../pages/miletsones/components/MemberAddedPage";
import { ProfileEditPage } from "../pages/profile/ProfileEditPage";
import { FirstYearPage } from "../pages/miletsones/components/FirstYearPage";
import { MonthlyDiary } from "../pages/miletsones/components/MonthlyDiaryPage";
import { ResetPassword } from "../pages/authentication/ResetPasswordPage";
import { WeAreInMotion } from "../pages/miletsones/components/WeAreInMotion";
import { FirstBirthday } from "../pages/miletsones/components/FirstBirthdayPage";
import { FirstHoliday } from "../pages/miletsones/components/FirstHolidayPage";
import { HolidayDetailsPage } from "../pages/miletsones/components/common/HolidayDetailsPage";
import { TeethChart } from "../pages/miletsones/components/TeethChartPage";

const PublicRoute = ({ element }: any) => {
  const { authUser } = React.useContext(AuthContext);

  if (authUser === false) {
    return <LoadingSpinner />;
  }
  return !authUser ? element : <Navigate to="/feed" replace />;
};

const PrivateRoute = ({ element, isMobile }: any) => {
  const { authUser } = React.useContext(AuthContext);

  if (authUser === false) {
    return <MobileSupportOnlyText />;
  }
  return authUser ? element : <Navigate to="/" replace />;
};

const AppRoutes = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 767px)"); // Adjust the breakpoint as needed

    const handleMobileChange = (event: any) => {
      setIsMobile(event.matches);
    };

    mobileMediaQuery.addEventListener("change", handleMobileChange);
    setIsMobile(mobileMediaQuery.matches);

    return () => {
      mobileMediaQuery.removeEventListener("change", handleMobileChange);
    };
  }, []);
  if (!isMobile) {
    return <MobileSupportOnlyText />;
  }
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route
          path="/"
          element={<PublicRoute element={<AuthenticationPage />} />}
        />
        <Route
          path="/register"
          element={<PublicRoute element={<Register />} />}
        />
        <Route path="/login" element={<PublicRoute element={<Login />} />} />
        <Route
          path="/reset-password"
          element={<PublicRoute element={<ResetPassword />} />}
        />
        {/* Private Routes */}
        <Route
          path="/feedback"
          element={<PrivateRoute element={<FeedbackPage />} />}
        />
        <Route
          path="/add-baby"
          element={<PrivateRoute element={<AddBabyForm />} />}
        />
        <Route
          path="/baby-info"
          element={<PrivateRoute element={<BabyInfo />} />}
        />
        <Route path="/feed" element={<PrivateRoute element={<FeedPage />} />} />
        <Route
          path="/milestones"
          element={<PrivateRoute element={<MilestonesPage />} />}
        />
        <Route path="/family-tree" element={<FamilyTree />} />
        <Route path="/first-year" element={<FirstYearPage />} />
        <Route path="/monthly-diary" element={<MonthlyDiary />} />
        <Route path="/in-motion" element={<WeAreInMotion />} />
        <Route path="/first-birthday" element={<FirstBirthday />} />
        <Route path="/first-holiday" element={<FirstHoliday />} />
        <Route
          path="/first-holiday/:holidayName" // Dynamic route parameter for holiday name
          element={<PrivateRoute element={<HolidayDetailsPage />} />}
        />
        <Route path="/teeth-chart" element={<TeethChart />} />
        <Route
          path="/profile"
          element={<PrivateRoute element={<ProfilePage />} />}
        />
        <Route
          path="/profile/edit"
          element={<PrivateRoute element={<ProfileEditPage />} />}
        />
        <Route
          path="/settings"
          element={<PrivateRoute element={<SettingsPage />} />}
        />
        <Route
          path="/member-added"
          element={<PrivateRoute element={<MemberAddedPage />} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;

const MobileSupportOnlyText = () => {
  const { authUser } = React.useContext(AuthContext);

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    margin: 0,
  };

  const headingStyle: any = {
    textAlign: "center",
  };
  if (authUser === false) {
    return <LoadingSpinner />;
  }
  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>We support only mobile view as of now.</h1>
    </div>
  );
};
