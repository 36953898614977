import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/style";
import { EditModal } from "../../../shared/EditModal";
import {
  CustomSelectInput,
  CustomTextArea,
  CustomTextInput,
} from "../../../../helpers/components/CustomInputs";
import Button from "../../../../helpers/components/Button";
import {
  FlexRowBetweenCenter,
  MediumIcon,
  SmallText,
} from "../../../shared/SharedStyles";
import goBackIcon from "../../../../images/goBackIcon.svg";
import { SharedButton } from "../../../settings/common/shared/SharedButton";
import penIcon from "../../../../images/penIconBlack.svg";
import { sendInvitation } from "../../../../api";
import { useBabyContext } from "../../../../context/BabyContext";
import { invitationRelationOptions } from "../../../../common/constants";
import { RelationType } from "../../../../interface/InvitationInterface";
import { validateEmail } from "../../../../common/helpers";

interface AddTextModalProps {
  isOpen: boolean;
  initialText: string;
  onClose: () => void;
  onSubmit: (text: string) => void | Promise<void>;
  parentComponent:
    | "firstBirthdayPage"
    | "holidayDetailsPage"
    | "profileContributors";
}

export const AddTextModal: React.FC<AddTextModalProps> = ({
  isOpen,
  initialText,
  onClose,
  onSubmit,
  parentComponent,
}) => {
  const [text, setText] = useState(
    initialText !== undefined ? initialText : ""
  );
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    relation: invitationRelationOptions[0].value,
  });
  const { selectedBabyId } = useBabyContext();

  const nonProfileContributorsComponent =
    parentComponent !== "profileContributors";

  const maxCharacters = 40;
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    if (newText.length <= maxCharacters) {
      setText(newText);
    }
  };

  const onSubmitClick = async () => {
    if (nonProfileContributorsComponent) {
      onSubmit(text);
    } else {
      // Send invitation
      const { email, relation } = inputData;
      try {
        await sendInvitation({
          baby_id: selectedBabyId as string,
          receiver_email: email.toLowerCase(),
          relation: relation as RelationType,
        });
      } catch (error) {}
    }
    onClose();
  };

  return (
    <EditModal isOpen={isOpen} onClose={onClose}>
      <HeaderContainer>
        <MediumIcon src={goBackIcon} onClick={onClose} />
        <SmallText color={colors.darkBrown} style={{ margin: "auto" }}>
          {nonProfileContributorsComponent ? "Add Text" : "Add Contributor"}
        </SmallText>
        <CloseButton
          onClick={() => onClose()}
          style={{ color: colors.darkBrown }}
        >
          <CloseIcon className="bi bi-x-lg" />
        </CloseButton>
      </HeaderContainer>
      <AddTextForm>
        {nonProfileContributorsComponent && (
          <CustomTextArea
            label=""
            onChange={handleChange}
            value={text}
            name="height"
            placeholder="Enter your text here"
            maxLength={maxCharacters}
          />
        )}
        {parentComponent === "profileContributors" &&
          renderInputInvitationInput()}
        {nonProfileContributorsComponent && (
          <CharacterCounter>
            {text.length}/{maxCharacters}
          </CharacterCounter>
        )}
        <SharedButton
          iconSrc={penIcon}
          buttonText={nonProfileContributorsComponent ? "Done" : "Send Invite"}
          onClick={onSubmitClick}
          $disabled={
            !nonProfileContributorsComponent && !validateEmail(inputData.email)
          }
        />
        <Button $button_type="colored" onClick={() => onClose()}>
          Cancel
        </Button>
      </AddTextForm>
    </EditModal>
  );

  function renderInputInvitationInput(): React.ReactNode {
    return (
      <>
        <CustomTextInput
          label="Full Name"
          placeholder="Enter full name"
          value={inputData.name}
          onChange={(e) => setInputData({ ...inputData, name: e.target.value })}
        />
        <CustomTextInput
          label="Email"
          placeholder="Enter email"
          value={inputData.email}
          onChange={(e) =>
            setInputData({ ...inputData, email: e.target.value })
          }
          forEmail={true}
        />
        <CustomSelectInput
          label="Relation"
          placeholder="Enter relation"
          value={
            invitationRelationOptions.find(
              (x) => x.value === inputData?.relation
            ) ?? invitationRelationOptions[0]
          }
          onChange={(selectedOption) =>
            setInputData({ ...inputData, relation: selectedOption.value })
          }
          options={invitationRelationOptions}
        />
      </>
    );
  }
};

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const AddTextForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 0 12px;
`;

const CharacterCounter = styled.div`
  align-self: flex-end;
  color: ${colors.softBrown};
  font-size: 14px;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 9%;
  right: 9%;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
const CloseIcon = styled.i`
  font-size: 20px;
`;
