import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/style";
import goBackIcon from "../../../images/goBackIcon.svg";
import Button from "../../../helpers/components/Button";
import {
  BigHeaderText,
  FlexRowBetweenCenter,
  MediumIcon,
  SmallTextSlim,
} from "../../shared/SharedStyles";
import { useNavigate } from "react-router-dom";
import bottomMouth from "../../../images/bottomMouth.svg";
import topMouth from "../../../images/topMouth.svg";
import { BackgroundImage } from "../../authentication/shared/SharedStyles";
import { getTeethChartData } from "../../../api";
import { useBabyContext } from "../../../context/BabyContext";
import { teethData } from "../../../common/constants";
import { AddTeethDateModal } from "./common/AddTeethDateModal";
import { formatTimestampToDefaultDate } from "../../../common/helpers";
import LoadingSpinner from "../../../common/LoadingSpinner";
import {
  MilestoneButtonWrapper,
  MilestoneContainer,
} from "../common/SharedComponents";

export const TeethChart = () => {
  const navigate = useNavigate();
  const { selectedBabyId } = useBabyContext();
  const goBackHandler = () => {
    navigate(-1);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(0);
  const [showDate, setShowDate] = useState(null);
  const [teethDataNodes, setTeethDataNodes] = useState<any>(teethData);
  const [loading, setLoading] = useState(false);

  const onTeethClick = (position: number) => {
    const clickedTooth = teethDataNodes.find(
      (tooth: any) => tooth.position === position
    );

    if (clickedTooth && clickedTooth.date) {
      setShowDate(clickedTooth.date);
    } else {
      setSelectedPosition(position);
      setIsModalOpen(true);
      setShowDate(null);
    }
  };

  const fetchTeethChartData = async () => {
    setLoading(true);
    await getTeethChartData(selectedBabyId)
      .then(({ data }) => {
        setTeethDataNodes((prev: any) => {
          return prev.map((teeth: any) => {
            const teethData = data.find(
              (d: any) => d.position === teeth.position
            );
            if (teethData) {
              return {
                ...teeth,
                date: teethData.date,
              };
            }
            return teeth;
          });
        });
      })
      .catch((err) => {});
    setLoading(false);
  };

  useEffect(() => {
    fetchTeethChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <MilestoneContainer>
        <HeaderContainer>
          <MediumIcon src={goBackIcon} onClick={goBackHandler} />
          <BigHeaderText>Teeth Chart</BigHeaderText>
          <div></div>
        </HeaderContainer>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <FamilyContainer $showDate={showDate}>
            <TeethWrapper>
              <BackgroundImage
                $blend_mode=""
                $position="absolute"
                $left=""
                $top=""
                $transform=""
                $width="100%"
                alt="Frame"
                src={topMouth}
              />
              {teethDataNodes.slice(10).map((toothProps: any) => (
                <Tooth
                  key={toothProps.position}
                  $left={toothProps.left}
                  $right={toothProps.right}
                  $top={toothProps.top}
                  $bottom={toothProps.bottom}
                  $zindex={toothProps.zindex}
                  src={
                    toothProps.date ? toothProps.boldedImage : toothProps.image
                  }
                  onClick={() => onTeethClick(toothProps.position)}
                />
              ))}
            </TeethWrapper>
            {showDate && (
              <Label>
                <SmallTextSlim $color={colors.white}>
                  {formatTimestampToDefaultDate(showDate)}
                </SmallTextSlim>
              </Label>
            )}
            <TeethWrapper>
              <BackgroundImage
                $blend_mode=""
                $position="absolute"
                $left=""
                $bottom=""
                $transform=""
                $width="100%"
                alt="Frame"
                src={bottomMouth}
              />
              {teethDataNodes.slice(0, 10).map((toothProps: any) => (
                <Tooth
                  key={toothProps.position}
                  $left={toothProps.left}
                  $right={toothProps.right}
                  $top={toothProps.top}
                  $bottom={toothProps.bottom}
                  $zindex={toothProps.zindex}
                  src={
                    toothProps.date ? toothProps.boldedImage : toothProps.image
                  }
                  onClick={() => onTeethClick(toothProps.position)}
                />
              ))}
            </TeethWrapper>
          </FamilyContainer>
        )}
        <MilestoneButtonWrapper>
          <Button $button_type="colored" onClick={goBackHandler}>
            Go Back
          </Button>
        </MilestoneButtonWrapper>
      </MilestoneContainer>
      {isModalOpen && (
        <AddTeethDateModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          position={selectedPosition}
          onRefresh={() => fetchTeethChartData()}
        />
      )}
    </React.Fragment>
  );
};

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

interface ContainerProps {
  $showDate: any;
}
const FamilyContainer = styled.div<ContainerProps>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 24px;
  gap: ${(props) => (props.$showDate ? "" : "12px")};
`;

interface ToothProps {
  $left?: string;
  $top?: string;
  $bottom?: string;
  $right?: string;
  $zindex?: string;
}
const TeethWrapper = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
`;

const Tooth = styled.img<ToothProps>`
  position: absolute;
  left: ${(props) => props.$left};
  top: ${(props) => props.$top};
  right: ${(props) => props.$right};
  bottom: ${(props) => props.$bottom};
  z-index: ${(props) => props.$zindex};
`;

const Label = styled.div`
  background-color: ${colors.darkBrown};
  border: 1px ${colors.darkBrown} solid;
  border-radius: 40px;
  width: fit-content;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
`;
