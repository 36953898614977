import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../helpers/components/Button";
import { OnboardingCard } from "../shared/OnboardingCard";
import bgFeet from "../../images/background_feet.svg";
import eye_icon from "../../images/eye_icon.svg";
import {
  CustomCheckbox,
  CustomPasswordInput,
  CustomTextInput,
} from "../../helpers/components/CustomInputs";
import {
  BackgroundImage,
  Description,
  GoBackButton,
  HeaderIcon,
  Headline,
  WelcomeText,
  ButtonWrapper,
  SubHeadline,
} from "./shared/SharedStyles";
import { useNavigate } from "react-router";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "./logic/firebaseConfig";
import { addUser } from "../../api";
import goBackIcon from "../../images/goBackIcon.svg";
import { errorMessages } from "../../common/constants";

export const Register = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    termsAccepted: false,
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    termsAccepted: "",
  });
  const [loading, setLoading] = useState(false);
  const isSubmitButtonDisabled =
    !userData.firstName ||
    !userData.lastName ||
    !userData.email ||
    !userData.password ||
    !userData.termsAccepted;
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...formErrors };

    // Validation for First Name
    if (!userData.firstName.trim()) {
      newErrors.firstName = errorMessages.firstNameError;
      isValid = false;
    } else {
      newErrors.firstName = "";
    }

    // Validation for Last Name
    if (!userData.lastName.trim()) {
      newErrors.lastName = errorMessages.lastNameError;
      isValid = false;
    } else {
      newErrors.lastName = "";
    }

    // Validation for Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userData.email.trim() || !emailRegex.test(userData.email)) {
      newErrors.email = errorMessages.emailAddressError;
      isValid = false;
    } else {
      newErrors.email = "";
    }

    // Validation for Password
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
    if (!passwordRegex.test(userData.password)) {
      newErrors.password = errorMessages.passwordError;
      isValid = false;
    } else {
      newErrors.password = "";
    }

    // Validation for Terms Acceptance
    if (!userData.termsAccepted) {
      newErrors.termsAccepted = errorMessages.termsAndConditionsError;
      isValid = false;
    } else {
      newErrors.termsAccepted = "";
    }

    setFormErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    const firebaseAuth = auth;
    let createdUser: any = null;
    await createUserWithEmailAndPassword(
      firebaseAuth,
      userData.email,
      userData.password
    )
      .then((userCredential) => {
        createdUser = userCredential.user;
        sendEmailVerification(createdUser);
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          setFormErrors({
            ...formErrors,
            email:
              "Email address already in use. Please use another email address.",
          });
        }
      });
    if (createdUser && createdUser.uid) {
      const { firstName, lastName, email } = userData;
      await addUser({
        first_name: firstName,
        last_name: lastName,
        email: email,
        accessToken: createdUser?.accessToken,
      }).then(() => {
        navigate("/login?registered=true&email=" + email);
      });
    }
    setLoading(false);
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <OnboardingCard>
      <HeaderIcon src={goBackIcon} onClick={goBackHandler} />
      <BackgroundImage $right="5%" $top="3%" alt="Frame" src={bgFeet} />
      <MainContainer>
        <WelcomeText>
          <SubHeadline>Register to</SubHeadline>
          <Headline>Little Feather</Headline>
          <Description $padding="0px 0px 20px 0px">
            Please enter your registration data below.
          </Description>
        </WelcomeText>
        <FormWrapper>
          <CustomTextInput
            label="First Name"
            placeholder="Enter your first name"
            name="firstName"
            value={userData.firstName}
            onChange={(e) =>
              setUserData({ ...userData, firstName: e.target.value })
            }
            error={formErrors.firstName}
          />
          <CustomTextInput
            label="Last Name"
            placeholder="Enter your last name"
            name="lastName"
            value={userData.lastName}
            onChange={(e) =>
              setUserData({ ...userData, lastName: e.target.value })
            }
            error={formErrors.lastName}
          />
          <CustomTextInput
            label="Email"
            placeholder="Enter email"
            name="email"
            value={userData.email}
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
            error={formErrors.email}
          />
          {/* <CustomDateInput
            label="Date of Birth"
            placeholder="Select Date"
            name="dateOfBirth"
            value={userData.dateOfBirth}
            onChange={(e) =>
              setUserData({ ...userData, dateOfBirth: e.target.value })
            }
          /> */}

          <CustomPasswordInput
            label="Password"
            placeholder="Enter password"
            icon={<img src={eye_icon} alt="Eye Icon" />}
            name="password"
            value={userData.password}
            onChange={(e) =>
              setUserData({ ...userData, password: e.target.value })
            }
            error={formErrors.password}
          />
          <CustomCheckbox
            checked={userData.termsAccepted}
            onChange={(isChecked) =>
              setUserData({ ...userData, termsAccepted: isChecked })
            }
            error={formErrors.termsAccepted}
          >
            <span>
              I have read and accept{" "}
              <StyledLink
                href="https://drive.google.com/file/d/1RTRKrgRW00jWDGRpTA_sIm3q9JMSluiL/view"
                target="_blank"
              >
                Terms and Conditions
              </StyledLink>{" "}
            </span>
          </CustomCheckbox>

          <ButtonWrapper>
            <Button
              $button_type="colored"
              onClick={(e) => handleSubmit(e)}
              disabled={isSubmitButtonDisabled || loading}
              loading={loading}
            >
              Register
            </Button>
            <GoBackButton onClick={goBackHandler}>Go Back</GoBackButton>
          </ButtonWrapper>
        </FormWrapper>
      </MainContainer>
    </OnboardingCard>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 90px 0px;
  position: relative;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledLink = styled.a`
  color: inherit;
`;
