import React, { useEffect } from "react";
import styled from "styled-components";
import { ListItemImage } from "./shared/ListItemImage";
import { NotificationsIcon } from "./shared/NotificationsIcon";
import { colors } from "../../../styles/style";
import { useBabyContext } from "../../../context/BabyContext";
import {
  calculateAgeFromTimestamp,
  capitalizeFirstLetter,
  getResizedImageUrl,
} from "../../../common/helpers";
import { HeaderText, SmallText } from "../../shared/SharedStyles";
import { useNavigate } from "react-router-dom";
import { imageSizes } from "../../../common/constants";

export const FeedHeader = () => {
  // @ts-ignore
  const isNative = window?.ReactNativeWebView;
  const { selectedBaby, refreshGetDefaultBaby } = useBabyContext();
  const navigate = useNavigate();

  useEffect(() => {
    const storedBabyId = localStorage.getItem("selectedBabyId");
    if (!selectedBaby && storedBabyId) {
      refreshGetDefaultBaby();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  return (
    <Container style={{ marginTop: isNative && "20px" }}>
      {selectedBaby && (
        <ListItemImage
          src={getResizedImageUrl(
            selectedBaby.profile_picture,
            imageSizes.thumbnail
          )}
          onError={(e) => {
            e.currentTarget.src = selectedBaby.profile_picture;
          }}
          alt="Baby Image"
          onClick={() => navigate("/profile")}
        />
      )}
      <HeaderInfo>
        <HeaderText>
          {selectedBaby ? `${selectedBaby?.name}` : "No Baby Selected"}
        </HeaderText>
        <SmallText $color={colors.softBrown}>
          {selectedBaby
            ? capitalizeFirstLetter(selectedBaby?.gender) +
              ", " +
              calculateAgeFromTimestamp(selectedBaby?.date_of_birth)
            : ""}
        </SmallText>
        {process.env.REACT_APP_FIREBASE_PROJECT_ID === "little-feather-dev" && (
          <HeaderText $color={colors.coralPink}>[Dev]</HeaderText>
        )}
      </HeaderInfo>
      <NotificationsIcon />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  gap: 12px;
  /* position: sticky;
  top: 0;
  max-width: 100%;
  background-color: ${colors.whiteBackgroundColor};
  border-radius: 16px; */
  margin: 0 6px;
`;

const HeaderInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
