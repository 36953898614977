import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase with the configuration
const app = initializeApp(
  process.env.REACT_APP_API_URL ===
    "https://little-feather-api-mvp-dev-b7539a71e043.herokuapp.com/api/v1"
    ? firebaseConfig
    : JSON.parse(process.env.REACT_APP_FIREBASE_CREDENTIALS_PROD ?? "") ?? ""
);
const auth = getAuth(app);

export { app, auth };
