import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useBabyContext } from "../../../context/BabyContext";
import goBackIcon from "../../../images/goBackIcon.svg";
import editIcon from "../../../images/editIcon.svg";
import { useNavigate } from "react-router-dom";
import { uploadImageToStorage } from "../../../common/helpers";
import { updateBabyData } from "../../../api";
import { AuthContext } from "../../../context/AuthContext";

export const ProfileEditPagePicture = () => {
  const { hasActivePayment } = React.useContext(AuthContext);
  const { selectedBaby, refreshBabyContext } = useBabyContext();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    fileInputRef.current!.click();
  };

  const handleGoBack = () => {
    navigate("/profile");
  };

  const handleImageInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    setLoading(true);
    const selectedImageFile = e.target.files?.[0];
    if (selectedImageFile) {
      const uploadedImage = await uploadImageToStorage(selectedImageFile);
      try {
        await updateBabyData(selectedBaby.id, {
          name: selectedBaby.name,
          gender: selectedBaby.gender,
          profile_picture: uploadedImage.imageUrl,
        });
        refreshBabyContext();
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  };
  return (
    <Container>
      <Image src={selectedBaby?.profile_picture} alt="Profile" />
      <IconContainer>
        <SmallIcon src={goBackIcon} onClick={handleGoBack} />
        <SmallIcon src={editIcon} onClick={handleUploadClick} />
      </IconContainer>
      <Input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleImageInputChange}
        disabled={loading || !hasActivePayment}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 32px;
  height: auto;
  max-height: 200px;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
`;

const Input = styled.input`
  display: none;
`;

const SmallIcon = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 12px;
  padding: 10px;
  background: #fff;
`;
