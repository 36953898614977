import styled from "styled-components";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery, { ReactImageGalleryProps } from "react-image-gallery";
import { colors } from "../../../styles/style";
import "bootstrap-icons/font/bootstrap-icons.css";
interface ImageGalleryModalProps {
  images: string[];
  onClose: () => void;
  thumbnail?: boolean;
}

interface CustomImageGalleryProps extends ReactImageGalleryProps {
  originalWidth?: number;
  originalHeight?: number;
}

export const ImageGalleryModal = ({
  images,
  onClose,
  thumbnail = true,
}: ImageGalleryModalProps) => {
  const transformedImages = images.map((url) => ({
    original: url,
    thumbnail: url,
    originalWidth: 700,
    thumbnailHeight: 50,
    thumbnailWidth: 50,
  }));

  const showThumbnails = images.length > 1;

  return (
    <ModalOverlay>
      <ImageContainer>
        {/* <Carousel showArrows={true} showThumbs={false} showStatus={false}>
          {images.map((imageFile, index) => (
            <ModalImage
              key={index}
              src={imageFile}
              alt={`Selected Image ${index + 1}`}
            />
          ))}
        </Carousel> */}
        <ImageGallery
          showPlayButton={false}
          lazyLoad={true}
          items={transformedImages}
          showFullscreenButton={false}
          showThumbnails={showThumbnails}
          // Omit 'items' from the spread
          {...({} as Omit<CustomImageGalleryProps, "items">)}
        />
      </ImageContainer>
      <CloseButton
        onClick={() => onClose()}
        style={{ color: colors.darkBrown }}
      >
        <CloseIcon className="bi bi-x-lg"></CloseIcon>
      </CloseButton>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.softPink};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 4%;
  right: 7%;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
// const ModalImage = styled.img`
//   max-width: 100%;
//   max-height: 100%;
//   object-fit: contain;
// `;

const CloseIcon = styled.i`
  font-size: 24px;
`;
