import styled from "styled-components";
import { colors } from "../../../styles/style";

export const MilestoneCard: React.FC<MilestoneCardProps> = ({
  $icon_src,
  title,
  $min_width,
  $max_width,
  width,
  onClick,
  $bgColor,
}) => {
  return (
    <Container
      $icon_src={$icon_src}
      title={title}
      $min_width={$min_width}
      $max_width={$max_width}
      width={width}
      onClick={onClick}
      $bgColor={$bgColor}
    >
      <CardLayout>
        <CardIcon src={$icon_src} alt="Milestone Icon" />
        <MilestoneTitle>{title}</MilestoneTitle>
      </CardLayout>
    </Container>
  );
};
interface MilestoneCardProps {
  $icon_src: string;
  title: string;
  $min_width?: string;
  $max_width?: string;
  width?: string;
  onClick?: (() => void) | undefined;
  $bgColor?: string;
}

const Container = styled.div<MilestoneCardProps>`
  border-radius: 20px;
  border: 1px solid ${colors.borderWhite};
  background-color: ${(props) => props.$bgColor || colors.bgWhite};
  min-width: ${(props) => props.$min_width || "150px"};
  max-width: ${(props) => props.$max_width || "100%"};
  width: ${(props) => props.width || "auto"};
  height: 92px;
  padding: 6px;
  flex-shrink: 0;
  border-radius: 20px;
`;

const CardIcon = styled.img``;

const CardLayout = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin: 40px 0 0 10px;
  width: fit-content;
  height: 32px;
  @media (max-width: 360px) {
    margin: 40px 0 0 8px; /* Adjust the margin for smaller screens */
  }
`;

const MilestoneTitle = styled.span`
  color: ${colors.darkBrown};
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;
