import React, { useContext } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/style";
import { AuthContext } from "../../../context/AuthContext";
import { capitalizeFirstLetter } from "../../../common/helpers";
import { BigText } from "../../shared/SharedStyles";

export const AccountOwner = () => {
  const authContext = useContext(AuthContext);
  const { authUser } = authContext;

  return (
    <Container>
      <NameAndButton>
        <BigText color={colors.darkBrown}>{`${capitalizeFirstLetter(
          authUser.first_name
        )} ${capitalizeFirstLetter(authUser.last_name)}`}</BigText>
        {/* <Button>Plan Admin</Button> */}
      </NameAndButton>
      {/* <SmallText color={colors.softBrown}>
        Plan Admin, Mother of Nicole and Sam
      </SmallText> */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 42px 0px 36px 0px;
`;

const NameAndButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 15px;
`;

// const Button = styled.button`
//   display: flex;
//   width: fit-content;
//   height: 32px;
//   padding: 7px 22px 7px 23px;
//   justify-content: center;
//   align-items: center;
//   flex-shrink: 0;
//   border-radius: 39px;
//   background: ${colors.warmthBrown};
//   color: ${colors.white};
//   ${SmallestText};
//   border: none;
// `;
