import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { BabyProvider } from "./context/BabyContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BabyProvider>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BabyProvider>
);
