import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles/style";
import { MilestoneCard } from "./MilestoneCard";
import { MilestoneWarningBadge } from "./MilestoneWarningBadge";
import { HeaderText, SmallestText } from "../../shared/SharedStyles";
import { useNavigate } from "react-router-dom";
import { milestonesData } from "../../../common/constants";
import { AuthContext } from "../../../context/AuthContext";

export const MilestoneList = () => {
  const navigate = useNavigate();
  const { hasActivePayment } = React.useContext(AuthContext);

  return (
    <Container>
      <MilestonesHeader>
        <HeaderText>Upcoming milestones</HeaderText>
        <SmallButton onClick={() => navigate("/milestones")}>
          See All
        </SmallButton>
      </MilestonesHeader>
      <ListedMilestones>
        {milestonesData.map((milestone, index) => (
          <MilestoneCard
            key={index}
            $icon_src={milestone.iconSrc}
            title={milestone.title}
            width="170px"
            $max_width=""
            $min_width=""
            onClick={() => navigate(milestone.url)}
            $bgColor={milestone.url === "" ? "#f0f0f0" : ""}
          />
        ))}
      </ListedMilestones>
      {!hasActivePayment && (
        <MilestoneWarningBadge
          text={`You are currently not subscribed to our app. Please subscribe to access all features.`}
          onClick={() => navigate("/settings")}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const MilestonesHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px 24px 16px;
`;

const SmallButton = styled.button`
  border: 1px solid ${colors.darkBrown};
  border-radius: 31px;
  display: flex;
  padding: 4px 8px;
  align-items: center;
  background-color: transparent;
  color: ${colors.softBrown};
  ${SmallestText};
  cursor: pointer;
`;

const ListedMilestones = styled.div`
  display: flex;
  gap: 12px;
  margin: 0;
  margin-bottom: 12px;
  padding-left: 16px;
  overflow-x: scroll;
  position: relative;
  &::-webkit-scrollbar {
    width: 0.5em;
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -16px;
    width: 16px;
  }

  &::after {
    content: "";
    flex-shrink: 0;
  }
`;
