import React, { useState } from "react";
import styled, { css } from "styled-components";
import NavItem from "./NavItem";
import cameraIcon from "../../images/cameraIcon.svg";
import { useLocation } from "react-router-dom";
import { colors } from "../../styles/style";
import AddPostModal from "./AddPostModal";
import { navItems } from "../../common/constants";
import { useBabyContext } from "../../context/BabyContext";
import { AuthContext } from "../../context/AuthContext";

export const NavigationMenu = () => {
  const { hasActivePayment } = React.useContext(AuthContext);
  const { selectedBaby } = useBabyContext();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  const handleImageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newImages = e.target.files ? Array.from(e.target.files) : [];
    if (newImages.length > 0) {
      setSelectedImages([...selectedImages, ...newImages]);
      openModal();
    }
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImages([]);
  };

  return (
    <React.Fragment>
      {isModalOpen && selectedImages.length > 0 && (
        <AddPostModal imageFiles={selectedImages} onClose={closeModal} />
      )}
      <MainContainer>
        <NavBarContainer>
          {navItems.map((item, index) => (
            <NavItem
              key={index}
              {...item}
              margin={
                index === 1
                  ? "0px 25px 0px 0px"
                  : index === 2
                    ? "0px 0px 0px 25px"
                    : ""
              }
              isActive={location.pathname === item.to}
              disabled={
                !hasActivePayment &&
                selectedBaby === null &&
                location.pathname !== item.to
              }
            />
          ))}
          <Label disabled={!hasActivePayment}>
            <LogoImage src={cameraIcon} alt="Logo" />
            <ImageInput
              type="file"
              accept="image/*"
              onChange={handleImageInputChange}
              multiple
              style={{ display: "none" }}
              disabled={!hasActivePayment || !selectedBaby}
            />
          </Label>
        </NavBarContainer>
      </MainContainer>
    </React.Fragment>
  );
};
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
`;
const NavBarContainer = styled.div`
  border-radius: 30px;
  background: ${colors.bgWhite};
  display: flex;
  justify-content: space-around;
  width: 90%;
  height: 72px;
  padding: 10px;
  height: 69px;
  margin-bottom: 12px;
  box-shadow: 0px -20px 42px 0px rgba(0, 0, 0, 0.1);
`;

const LogoImage = styled.img`
  width: 29px;
  height: 26px;
`;
const ImageInput = styled.input`
  display: none;
`;

const Label = styled.label<{ disabled?: boolean }>`
  position: absolute;
  top: -12%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: ${colors.warmthPink};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`;
