import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import filterIcon from "../../../images/filterIcon.svg";
import { Post } from "./Post";
import { colors } from "../../../styles/style";
// import { dummyPosts } from "../../../helpers/components/dummyData";
import { getAllPostsByBabyId } from "../../../api";
import { useBabyContext } from "../../../context/BabyContext";
import { HeaderText } from "../../shared/SharedStyles";
import { LocalLoadingSpinner } from "../../../common/LoadingSpinner";

interface FileReference {
  post_id: string;
  type: string;
  url: string;
}

interface CreatorUser {
  id: string;
  date_of_birth: number;
  firebase_id: string;
  last_name: string;
  first_name: string;
  email: string;
}

export interface PostObject {
  id: string;
  baby_id: string;
  is_feed_post: boolean;
  creator_id: string;
  created_at: number;
  file_references: FileReference[];
  creator_user: CreatorUser;
}

export const Posts = () => {
  const { selectedBaby, postAdded, refreshGetDefaultBaby } = useBabyContext();
  const [posts, setPosts] = useState<PostObject[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await getAllPostsByBabyId(selectedBaby.id);
      setPosts(response.data);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    const selectedBabyIdStored = localStorage.getItem("selected-baby");
    if (!selectedBaby && selectedBabyIdStored) {
      refreshGetDefaultBaby();
    }
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBaby, postAdded]);

  return (
    <Container>
      <ContainerHeader>
        <HeaderText>Recent activity</HeaderText>
        {/* <SmallIcon src={filterIcon} /> */}
      </ContainerHeader>
      <PostsContainer>
        {posts && !loading ? (
          posts.map((post, index) => (
            <Post
              key={index}
              username={
                post.creator_user
                  ? post.creator_user?.first_name
                  : "Removed User"
              }
              date={post.created_at}
              images={post.file_references.map((fileRef) => fileRef.url)}
            />
          ))
        ) : loading ? (
          <LocalLoadingSpinner />
        ) : (
          !selectedBaby && "Please select your baby to see posts"
        )}
      </PostsContainer>
    </Container>
  );
};

const Container = styled.div`
  flex-grow: 1;
  padding: 24px;
  border-radius: 32px;
  background: ${colors.bgWhite};
  padding-bottom: 70px;
`;
const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const PostsContainer = styled.div`
  flex-grow: 1;
`;
