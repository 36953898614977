import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles/style";
import warningIcon from "../../../images/warningIcon.svg";
import { SmallIcon, SmallestText } from "../../shared/SharedStyles";

export const MilestoneWarningBadge = (props: any) => {
  return (
    <Container onClick={props.onClick}>
      <SmallIcon src={warningIcon} />
      {props.text}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 16px;
  padding: 8px 16px 8px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 52px;
  border: 1px solid ${colors.bgWhite};
  background: ${colors.white};
  width: fit-content;
  max-width: 100%;
  color: ${colors.darkBrown};
  ${SmallestText};
`;
