import React from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/style";
import { SmallTextConstant } from "../../../shared/SharedStyles";
import { ButtonSpinner } from "../../../../common/LoadingSpinner";

interface StyledButtonProps {
  $margin?: string;
  $disabled?: boolean;
  $borderRadius?: string;
  $color?: string;
  $backgroundColor?: string;
  $borderColor?: string;
}

const StyledButton = styled.button<StyledButtonProps>`
  background-color: ${(props) => props.$backgroundColor || "transparent"};
  display: flex;
  gap: 8px;
  width: 100%;
  min-height: 54px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.$borderRadius || "32px"};
  border: 1px solid ${(props) => props.$borderColor || colors.darkBrown};
  color: ${(props) => props.$color || colors.softBrown};
  ${SmallTextConstant};
  margin: ${(props) => props.$margin || "0"};
  opacity: ${(props) => (props.$disabled ? "0.4" : "")};
`;

const Icon = styled.img`
  width: 19px;
  height: 19px;
`;

interface ReusableButtonProps {
  iconSrc?: string;
  buttonText: string;
  onClick?: () => void | Promise<void>;
  $margin?: string;
  $disabled?: boolean;
  loading?: boolean;
  $borderRadius?: string;
  $color?: string;
  $backgroundColor?: string;
  $borderColor?: string;
}

export const SharedButton: React.FC<ReusableButtonProps> = ({
  iconSrc,
  buttonText,
  onClick,
  $margin,
  $disabled,
  loading,
  $borderRadius,
  $color,
  $backgroundColor,
  $borderColor,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      $margin={$margin}
      disabled={$disabled}
      $disabled={$disabled}
      $borderRadius={$borderRadius}
      $color={$color}
      $backgroundColor={$backgroundColor}
      $borderColor={$borderColor}
    >
      {loading ? (
        <ButtonSpinner />
      ) : (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {iconSrc && <Icon src={iconSrc} />}
          {buttonText && <span>{buttonText}</span>}
        </div>
      )}
    </StyledButton>
  );
};
