import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../../../helpers/components/Button";
import bgImageFlower from "../../../images/bg_image_flower.svg";
import congratsImage from "../../../images/congrats.svg";
import {
  BackgroundImage,
  ButtonWrapper,
} from "../../authentication/shared/SharedStyles";
import { OnboardingCard } from "../../shared/OnboardingCard";
import { useNavigate, useLocation } from "react-router";
import { colors } from "../../../styles/style";
import {
  BigText,
  MediumText,
  SmallTextMedium,
  SmallestText,
} from "../../shared/SharedStyles";
import { MilestoneSelectedImage } from "../common/SharedComponents";

interface FamilyMember {
  image: string;
  label: string;
  position: number;
}

export const MemberAddedPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [filteredMembers, setFilteredMembers] = useState<FamilyMember[]>([]);

  useEffect(() => {
    const filteredArray = Object.values(
      state.modifiedFamilyMembers
    ) as FamilyMember[];
    const filteredMembersWithImages = filteredArray.filter((member) => {
      const currentImage = member.image;
      return currentImage != null;
    });
    setFilteredMembers(filteredMembersWithImages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    navigate("/milestones");
  };

  return (
    <OnboardingCard $margin="0">
      <BackgroundImage
        $position="fixed"
        $left="50%"
        $bottom="-14%"
        $transform="translate(-50%)"
        $width="390px"
        alt="Frame"
        src={bgImageFlower}
      />
      <MainContainer>
        <AdditionalImage src={congratsImage} alt="Additional Image" />
        <ContentContainer>
          <MediumText color={colors.warmthBrown} $margin_top="60px">
            <BigText color={colors.darkBrown} $padding_bottom="32px">
              Congratulations!
            </BigText>
            <Paragraph>You added new images to the family tree.</Paragraph>
          </MediumText>
          <AddedMembersWrapper>
            <FirstRowWrapper>
              {filteredMembers.map((member, index) => (
                <ImageInputContainer key={index}>
                  <Input type="file" disabled />

                  <MilestoneSelectedImage
                    src={member.image}
                    alt={`${member.label}'s Image`}
                  />

                  <NameOfRelationship>{member.label}</NameOfRelationship>
                </ImageInputContainer>
              ))}
            </FirstRowWrapper>
          </AddedMembersWrapper>
          <ButtonWrapper>
            <Button $button_type="colored" onClick={handleClose}>
              Close
            </Button>
          </ButtonWrapper>
        </ContentContainer>
      </MainContainer>
    </OnboardingCard>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  padding-bottom: 90px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  width: 100%;
  padding: 0px 40px;
`;

const AdditionalImage = styled.img`
  width: 100%;
`;

const Paragraph = styled.div`
  color: ${colors.warmthBrown};
  ${SmallTextMedium};
`;

const AddedMembersWrapper = styled.div`
  margin: 24px 0px;
  display: flex;
`;
const FirstRowWrapper = styled.div`
  gap: 12px;
  padding: 32px 0 px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
`;

const ImageInputContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  border: 2px solid black; /* Brown border color */
  border-radius: 50%; /* Make it round */
  cursor: pointer;
`;

const Input = styled.input`
  display: none;
`;

const NameOfRelationship = styled.div`
  position: absolute;
  padding: 2px 8px;
  bottom: -15%;
  background-color: ${colors.darkBrown};
  border-radius: 40px;
  color: ${colors.white};
  ${SmallestText};
`;
