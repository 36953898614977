import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../helpers/components/Button";
import { OnboardingCard } from "../shared/OnboardingCard";
import bgFeet from "../../images/background_feet.svg";
import { CustomTextInput } from "../../helpers/components/CustomInputs";
import {
  BackgroundImage,
  Description,
  GoBackButton,
  HeaderIcon,
  Headline,
  ButtonWrapper,
  WelcomeText,
} from "./shared/SharedStyles";
import { useNavigate } from "react-router";
import goBackIcon from "../../images/goBackIcon.svg";
import { auth } from "./logic/firebaseConfig";
import { sendPasswordResetEmail } from "firebase/auth";
import { errorMessages } from "../../common/constants";

export const ResetPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [resetError, setResetError] = useState("");
  const [resetSuccess, setResetSuccess] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (resetSuccess) {
      navigate("/login");
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setResetSuccess(errorMessages.passwordResetSuccess);
      setResetError("");
    } catch (error: any) {
      setResetError(errorMessages.passwordResetError);
      setResetSuccess("");
    }
  };
  return (
    <OnboardingCard>
      <HeaderIcon src={goBackIcon} onClick={() => navigate(-1)} />
      <BackgroundImage $right="5%" $top="3%" alt="Frame" src={bgFeet} />
      <MainContainer>
        <WelcomeText>
          <Headline>Reset Password</Headline>
          <Description $padding="0px 110px 20px 0px">
            Please enter your email address below and click the Send Reset Email
            Button once you do.
          </Description>
        </WelcomeText>
        <FormWrapper onSubmit={handleSubmit}>
          <CustomTextInput
            label="Email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {resetError && <Description>{resetError}</Description>}
          {resetSuccess && <Description>{resetSuccess}</Description>}
          <ButtonWrapper>
            <Button $button_type="colored" type="submit">
              {resetSuccess ? "Login" : "Send Reset Email"}
            </Button>
            <GoBackButton onClick={() => navigate(-1)}>Go Back</GoBackButton>
          </ButtonWrapper>
        </FormWrapper>
      </MainContainer>
    </OnboardingCard>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 90px 0px 0px 0;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  margin-top: 60px;
`;
