import React, { ReactNode } from "react";
import styled from "styled-components";

interface FeedCardProps {
  children: ReactNode;
  margin?: string; // New prop for margin
}

const Container = styled.div`
  /* display: flex; */
  height: 100vh;
  max-height: 100%;
  min-height: 100vh;
  overflow-y: auto;
`;

const MainWrapper = styled.div<FeedCardProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* max-height: 100%; */
`;

export const FeedCard: React.FC<FeedCardProps> = ({ children, margin }) => {
  // @ts-ignore
  const isNative = window?.ReactNativeWebView;
  return (
    <Container style={{ paddingTop: isNative && "30px" }}>
      <MainWrapper margin={margin}>{children}</MainWrapper>
    </Container>
  );
};
