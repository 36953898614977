import React, { useRef, useState } from "react";
import styled from "styled-components";
import goBackIcon from "../../../../images/goBackIcon.svg";
import {
  BigHeaderText,
  FlexRowBetweenCenter,
  MediumIcon,
  ModalOverlay,
  SmallIcon,
} from "../../../shared/SharedStyles";
import { EditModal } from "../../../shared/EditModal";
import { SharedButton } from "../../../settings/common/shared/SharedButton";
import cameraIcon from "../../../../images/cameraIconBlack.svg";
import remove_image_icon from "../../../../images/remove-image-icon.svg";
import {
  formatDateForInputValue,
  uploadImageToStorage,
} from "../../../../common/helpers";
import { ImageCropper } from "./CropImage";
import { CustomDateInput } from "../../../../helpers/components/CustomInputs";
import Button from "../../../../helpers/components/Button";

interface WeAreInMotionModalProps {
  showModal: boolean;
  handleSetShowModal: React.Dispatch<React.SetStateAction<any>>;
  cardData: any; // Adjust the type as needed
  onCardUpdate: (imageUrl: string, date: number) => void; // Date is epoch time in seconds
}

export const WeAreInMotionModal: React.FC<WeAreInMotionModalProps> = ({
  showModal,
  handleSetShowModal,
  cardData,
  onCardUpdate,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const selectedImageInputRef = useRef<HTMLInputElement>(null);
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [cardDataToChange, setCardDataToChange] = useState<any>({
    image: cardData.image,
    date: cardData.date,
  });
  const handleAddPhoto = () => {
    selectedImageInputRef.current?.click();
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedImageFile = e.target.files?.[0];
    if (selectedImageFile) {
      setShowCropperModal(true);
      setUploadedFile(selectedImageFile);
      setCardDataToChange({
        ...cardDataToChange,
        imageFile: selectedImageFile,
      });
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value;
    // const epochTime = Math.floor(new Date(selectedDate).getTime() / 1000); // Convert to seconds
    setCardDataToChange({
      ...cardDataToChange,
      date: selectedDate,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);

    let updatedImageUrl = cardDataToChange.image;

    if (cardDataToChange?.imageFile) {
      // Upload the image if there's a new one selected
      const uploadedImage = await uploadImageToStorage(
        uploadedFile,
        "images/we-are-in-motion/"
      );
      updatedImageUrl = uploadedImage.imageUrl;
    }

    // Update the card data
    setCardDataToChange({
      ...cardDataToChange,
      image: updatedImageUrl,
    });

    // Call the update function passed from the card
    cardDataToChange.date =
      cardDataToChange.date < 10000000000
        ? cardDataToChange.date * 1000
        : cardDataToChange.date;

    onCardUpdate(updatedImageUrl, cardDataToChange.date);

    // Close the modal after updating
    handleSetShowModal(false);
    setLoading(false);
  };

  return (
    <>
      <EditModal isOpen={showModal} onClose={() => handleSetShowModal(false)}>
        <HeaderContainer>
          <MediumIcon
            src={goBackIcon}
            onClick={() => handleSetShowModal(false)}
          />
          <BigHeaderText>Update Entry</BigHeaderText>
          <div></div>
        </HeaderContainer>

        <ButtonsWrapper>
          {cardDataToChange?.imageFile || cardDataToChange?.image ? (
            <SelectedInputImageContainer>
              <SelectedInputImage
                src={
                  uploadedFile
                    ? URL.createObjectURL(uploadedFile)
                    : cardDataToChange?.image
                }
                alt="Selected"
              />
              <SmallIcon
                src={remove_image_icon}
                alt="Remove"
                onClick={() => {
                  setCardDataToChange({
                    ...cardDataToChange,
                    imageFile: null,
                    image: null,
                  });
                }}
              />
            </SelectedInputImageContainer>
          ) : (
            <SharedButton
              iconSrc={cameraIcon}
              buttonText="Add a photo"
              onClick={handleAddPhoto}
            />
          )}

          {/* Date Picker Input */}
          <CustomDateInput
            label=""
            placeholder="Select Date"
            value={
              cardDataToChange.date
                ? typeof cardDataToChange.date === "number"
                  ? formatDateForInputValue(
                      new Date(cardDataToChange.date * 1000)
                    ) // Handle epoch time
                  : formatDateForInputValue(new Date(cardDataToChange.date)) // Handle regular date string
                : ""
            }
            onChange={handleDateChange}
          />

          {/* Submit Button */}
          <SharedButton
            buttonText="Submit"
            onClick={handleSubmit}
            $disabled={
              loading || !cardDataToChange?.date || !cardDataToChange?.image
            }
            loading={loading}
          />
          <Button
            $button_type="colored"
            onClick={() => handleSetShowModal(false)}
          >
            Cancel
          </Button>
        </ButtonsWrapper>

        <ImageInput
          type="file"
          accept="image/*"
          ref={selectedImageInputRef}
          onChange={handleImageChange}
        />
      </EditModal>

      {/* Cropper Modal */}
      {showCropperModal && (
        <ModalOverlay>
          <ImageCropper
            image={uploadedFile}
            croppedImageAction={(croppedImage: any) => {
              setUploadedFile(croppedImage);
              setShowCropperModal(false);
            }}
            closeModal={() => {
              setShowCropperModal(false);
              setUploadedFile(null);
              setCardDataToChange({
                ...cardDataToChange,
                imageFile: null,
                image: null,
              });
            }}
          />
        </ModalOverlay>
      )}
    </>
  );
};

// Styled components for the modal layout
const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ImageInput = styled.input`
  display: none;
`;

const SelectedInputImage = styled.img`
  width: 144px;
  height: 78px;
  flex-shrink: 0;
  border-radius: 12px;
`;

const SelectedInputImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  border-radius: 16px;
  border: 1px solid #d9dcdf;
  padding: 8px;
`;
