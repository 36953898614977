import styled from "styled-components";
import { colors } from "../../../styles/style";

// Define the prop type for the styled component
interface HeaderAndEditWrapperProps {
  $padding?: string; // Define the margin prop
}

// Use the prop type with the styled function
export const HeaderAndEditWrapper = styled.div<HeaderAndEditWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  padding: ${(props) =>
    props.$padding || "0"}; // Use the passed margin or default to 0
`;

export const HeaderText = styled.h2`
  color: ${colors.darkBrown};
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.3px;
`;
