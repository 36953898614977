import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/style";
import { HeaderAndEditWrapper, HeaderText } from "../shared/SharedStyles";
import { SmallIcon, SwitchContainer, Option } from "../../shared/SharedStyles";
import editIcon from "../../../images/editIcon.svg";
import { getAllPostsByBabyId } from "../../../api";
import { useBabyContext } from "../../../context/BabyContext";
import { PostObject } from "../../feed/common/Posts";
import { useNavigate } from "react-router-dom";
import { getMonthText, getResizedImageUrl } from "../../../common/helpers";
import { ImageGalleryModal } from "../../feed/common/ImageGalleryModal";
import { imageSizes } from "../../../common/constants";

export const ProfileTimeline = () => {
  const [activeOption, setActiveOption] = useState<string>("months");
  const [posts, setPosts] = useState<PostObject[] | undefined>(undefined);
  const { selectedBaby } = useBabyContext();
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string[]>([]);
  const navigate = useNavigate();

  const onEditClickHandler = () => {
    navigate("/profile/edit");
  };

  const handleSwitch = () => {
    const newOption = activeOption === "months" ? "days" : "months";
    setActiveOption(newOption);
  };

  const fetchPosts = async () => {
    try {
      const { data } = await getAllPostsByBabyId(selectedBaby.id);
      setPosts(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBaby]);

  const allImagesFromPosts = posts?.flatMap((post) => post.file_references);

  const groupPostsByTime = (groupingLevel: string) => {
    const groupedPosts: { [key: string]: PostObject[] } = {};

    if (posts) {
      posts.forEach((post) => {
        const postDate = new Date(post.created_at * 1000);

        let timeKey: string;
        if (groupingLevel === "months") {
          timeKey = `${postDate.getMonth() + 1}-${postDate.getFullYear()}`;
        } else if (groupingLevel === "days") {
          const startOfDay = new Date(
            postDate.getFullYear(),
            postDate.getMonth(),
            postDate.getDate()
          ).getTime();
          timeKey = startOfDay.toString();
        } else {
          timeKey = `${postDate.getMonth() + 1}-${postDate.getFullYear()}`;
        }

        if (!groupedPosts[timeKey]) {
          groupedPosts[timeKey] = [];
        }

        groupedPosts[timeKey].push(post);
      });
    }

    return groupedPosts;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let allImagesByMonth = groupPostsByTime(activeOption);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    allImagesByMonth = groupPostsByTime(activeOption);
  }, [activeOption]);

  const formatUnixTimestamp = (timestamp: any) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    return `${day} ${month}`;
  };

  const handleImageClick = (url: string) => () => {
    setSelectedImage([url]);
    setShowModal(true);
  };
  return (
    <>
      <Container>
        <TimelineHeaderWrapper>
          <TimelineHeader>
            {selectedBaby && selectedBaby.name}'s Timeline
          </TimelineHeader>

          <SmallIcon src={editIcon} onClick={onEditClickHandler} />
        </TimelineHeaderWrapper>
        {allImagesFromPosts && allImagesFromPosts.length > 0 && (
          <>
            <SwitchContainer onClick={handleSwitch}>
              <Option $active={(activeOption === "months").toString()}>
                Months
              </Option>
              <Option $active={(activeOption === "days").toString()}>
                Days
              </Option>
            </SwitchContainer>

            {Object.entries(allImagesByMonth).map(([monthYear, monthPosts]) => {
              const [month, year] = monthYear.split("-");
              const formattedMonthYear =
                getMonthText(parseInt(month)) + " " + year;
              return (
                <React.Fragment key={monthYear}>
                  <HeaderAndEditWrapper $padding="0 24px">
                    <HeaderText>
                      {activeOption === "months"
                        ? formattedMonthYear
                        : formatUnixTimestamp(parseInt(monthYear))}
                    </HeaderText>
                  </HeaderAndEditWrapper>
                  <ImageContainer>
                    {monthPosts.flatMap((post, index) =>
                      post.file_references.map((fileReference, fileIndex) => (
                        <Image
                          key={`${index}-${fileIndex}`}
                          alt={`Image ${index}-${fileIndex}`}
                          onClick={handleImageClick(fileReference.url)}
                          src={getResizedImageUrl(
                            fileReference.url,
                            imageSizes.medium
                          )}
                          onError={(e) => {
                            e.currentTarget.src = fileReference.url;
                          }}
                        />
                      ))
                    )}
                  </ImageContainer>
                </React.Fragment>
              );
            })}
          </>
        )}
      </Container>
      {showModal && (
        <ImageGalleryModal
          images={selectedImage}
          onClose={() => setShowModal(false)}
          thumbnail={false}
        />
      )}
    </>
  );
};

const Container = styled.div`
  flex-grow: 1;
  border-radius: 32px;
  margin-top: 24px;
  background: ${colors.bgWhite};
  padding-bottom: 100px;
`;

const TimelineHeader = styled.h2`
  color: ${colors.darkBrown};
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.3px;
`;

const ImageContainer = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 7px;
  margin: 12px;
  margin-top: 24px;
  @media (max-width: 330px) {
    grid-template-columns: repeat(2, 1fr); /* 3 columns on larger screens */
  }
`;

const Image = styled.img`
  width: 116px;
  height: 104px;
  border-radius: 16px;
  border: 0px solid ${colors.white};
`;

const TimelineHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 24px 0 24px;
`;
