import React from "react";
import styled from "styled-components";
import { buttonsBorderRadius, colors } from "../../styles/style";
import { SmallestText } from "../shared/SharedStyles";

export const SubscriptionUpgrade = (props: any) => {
  return (
    <Container margin={props.margin}>
      {props.text}
      <StyledButton onClick={props.onClick} disabled={props.disabled}>
        Subscribe
      </StyledButton>
    </Container>
  );
};

const Container = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${(props) => props.margin || "0 16px"};
  padding: 8px 16px 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 52px;
  border: 1px solid ${colors.bgWhite};
  background: ${colors.white};
  width: fit-content;
  max-width: 100%;
  color: ${colors.darkBrown};
  ${SmallestText};
`;

const StyledButton = styled.button<{ disabled?: string }>`
  width: fit-content;
  border: 1px solid ${colors.warmthBrown};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-weight: 700;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  background-color: ${colors.whiteBackgroundColor};
  color: ${colors.warmthBrown};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${buttonsBorderRadius};
  padding: 10px 14px;
`;
