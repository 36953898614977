import React from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/style";
import { getMonthText, getResizedImageUrl } from "../../../../common/helpers";
import { imageSizes } from "../../../../common/constants";

interface MonthlyDiaryCardProps {
  image: string;
  month: number;
  text: string;
  firstRow?: boolean;
}

export const MonthlyDiaryCard: React.FC<MonthlyDiaryCardProps> = ({
  image,
  month,
  text,
  firstRow,
}) => {
  return (
    <Container>
      <ImageContainer>
        <Image
          alt="Monthly Diary"
          $firstRow={firstRow}
          src={
            firstRow
              ? getResizedImageUrl(image, imageSizes.large)
              : getResizedImageUrl(image, imageSizes.medium)
          }
          onError={(e) => {
            e.currentTarget.src = image;
          }}
        />
        <Header>{getMonthText(month)}</Header>
      </ImageContainer>
      <TextContainer>
        <Text>{text}</Text>
      </TextContainer>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #e8dcdc;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  position: relative;
`;

interface ImageProps {
  $firstRow?: boolean;
}

const Image = styled.img<ImageProps>`
  width: 100%;
  height: ${(props) => (props.$firstRow ? "130px" : "92px")};
  object-fit: cover;
`;

const Header = styled.h2`
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: ${colors.white};
  font-size: 20px;
  font-weight: 500;
`;

const TextContainer = styled.div`
  width: 100%;
  height: 54px;
  padding: 0 8px;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.darkBrown};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
