import React from "react";
import styled from "styled-components";
import { ListItemImage } from "../../feed/common/shared/ListItemImage";
import goBackIcon from "../../../images/goBackIcon.svg";
import { useNavigate } from "react-router-dom";
import { useBabyContext } from "../../../context/BabyContext";
import {
  BigHeaderText,
  FlexRowBetweenCenter,
  MediumIcon,
} from "../../shared/SharedStyles";
import { getResizedImageUrl } from "../../../common/helpers";
import { imageSizes } from "../../../common/constants";

export const MilestonesHeader = () => {
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate(-1);
  };
  const { selectedBaby } = useBabyContext();

  return (
    <Container>
      <MediumIcon src={goBackIcon} onClick={goBackHandler} />
      <BigHeaderText>Milestones</BigHeaderText>
      {selectedBaby ? (
        <ListItemImage
          alt="Baby Image"
          src={getResizedImageUrl(
            selectedBaby.profile_picture,
            imageSizes.thumbnail
          )}
          onError={(e) => {
            e.currentTarget.src = selectedBaby.profile_picture
              ? selectedBaby.profile_picture
              : ListItemImage;
          }}
        ></ListItemImage>
      ) : (
        <div></div>
      )}
    </Container>
  );
};

const Container = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;
