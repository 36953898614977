import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/style";
import goBackIcon from "../../../images/goBackIcon.svg";
import Button from "../../../helpers/components/Button";
import {
  BigHeaderText,
  FlexRowBetweenCenter,
  MediumIcon,
  SmallText,
} from "../../shared/SharedStyles";
import { useNavigate } from "react-router-dom";
import { SharedButton } from "../../settings/common/shared/SharedButton";
import { GoBackButton } from "../../authentication/shared/SharedStyles";
import { holidayData } from "../../../common/constants";
import {
  MilestoneButtonWrapper,
  MilestoneWrapper,
} from "../common/SharedComponents";

export const FirstHoliday = () => {
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate(-1);
  };

  const [selectedHoliday, setSelectedHoliday] = useState(holidayData[0].value);

  const handleHolidaySelect = (holiday: any) => {
    setSelectedHoliday(holiday);
  };

  return (
    <React.Fragment>
      <Container>
        <HeaderContainer>
          <MediumIcon src={goBackIcon} onClick={goBackHandler} />
          <BigHeaderText>My first holiday</BigHeaderText>
          <div></div>
        </HeaderContainer>
        <MilestoneWrapper>
          <ContentWrapper>
            <SmallText $color={colors.warmthBrown}>
              Please select your Holiday type for a customized experience.
            </SmallText>
            <HolidaysWrapper>
              {holidayData.map((holiday) => (
                <SharedButton
                  key={holiday.value}
                  buttonText={holiday.name}
                  $borderRadius="16px"
                  $color={colors.darkBrown}
                  $borderColor={colors.borderWhite}
                  $backgroundColor={
                    selectedHoliday === holiday.value
                      ? colors.warmthPink
                      : colors.white
                  }
                  onClick={() => handleHolidaySelect(holiday.value)}
                />
              ))}
            </HolidaysWrapper>
          </ContentWrapper>
        </MilestoneWrapper>
        {/* <ButtonContainer>
          <CustomButton>
            <SmallIcon src={createCard} alt="Create Card" />
            <SmallTextSlim>Create Card</SmallTextSlim>
          </CustomButton>
          <CustomButton>
            <SmallIcon src={makeMemory} alt="Make Memory" />
            <SmallTextSlim>Make Memory</SmallTextSlim>
          </CustomButton>
        </ButtonContainer> */}
        <MilestoneButtonWrapper>
          <Button
            $button_type="colored"
            onClick={() =>
              navigate(`/first-holiday/${selectedHoliday}`, {
                state: { holidayName: selectedHoliday },
              })
            }
          >
            Select
          </Button>
          <GoBackButton onClick={goBackHandler}>Cancel</GoBackButton>
        </MilestoneButtonWrapper>
      </Container>
    </React.Fragment>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  padding-top: 40px;
  background-color: ${colors.whiteBackgroundColor};
  overflow-y: scroll;
`;

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 20px 32px;
`;

const HolidaysWrapper = styled.div`
  width: 100%;
  gap: 12px;
  display: flex;
  flex-direction: column;
  margin: 36px 0px;
`;

// const ButtonContainer = styled.div`
//   display: flex;
//   margin: 20px 32px;
//   gap: 12px;
// `;

// const CustomButton = styled.div`
//   flex-grow: 1;
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   gap: 6px;
//   padding: 20px 4px;
//   border-radius: 16px;
//   border: 1px solid ${colors.borderWhite};
//   background-color: ${colors.bgWhite};
//   color: ${colors.softBrown};
// `;
