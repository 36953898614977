// src/components/Button.tsx
import React from "react";
import styled, { css } from "styled-components";
import {
  buttonsBorderRadius,
  colors,
  fontSize,
  spacing,
} from "../../styles/style";
import { ButtonSpinner } from "../../common/LoadingSpinner";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $button_type: "primary" | "colored";
  disabled?: boolean; // Add the disabled prop
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  $button_type,
  children,
  loading,
  ...rest
}) => {
  return (
    <StyledButton $button_type={$button_type} {...rest}>
      {loading ? <ButtonSpinner /> : children}
    </StyledButton>
  );
};

export default Button;

const primaryStyles = css`
  background: ${colors.whiteBackgroundColor};
  color: ${colors.warmthBrown};
`;

const coloredStyles = css<ButtonProps>`
  background-color: ${colors.warmthBrown};
  color: ${colors.white};
`;

const StyledButton = styled.button<ButtonProps>`
  width: 100%;
  height: 55px;
  padding: ${spacing.sm};
  border-radius: ${buttonsBorderRadius};
  border: 1px solid ${colors.warmthBrown};
  cursor: ${(props) =>
    props.disabled
      ? "not-allowed"
      : "pointer"}; // Change cursor based on disabled prop
  color: ${colors.white};
  font-size: ${fontSize.md};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 8px 0px;
  opacity: ${(props) =>
    props.disabled ? 0.8 : 1}; // Change opacity based on disabled prop
  ${(props) => props.$button_type === "primary" && primaryStyles}
  ${(props) => props.$button_type === "colored" && coloredStyles}
  display: flex;
  justify-content: center;
  align-items: center;
`;
