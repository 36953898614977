import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ListItemWithImage } from "./shared/ListItemWithImage";
import addIcon from "../../../images/addIcon.svg";
import { SharedButton } from "./shared/SharedButton";
import { useNavigate } from "react-router";
import { getAllBabies } from "../../../api";
import { LocalLoadingSpinner } from "../../../common/LoadingSpinner";
import { HeaderText } from "../../shared/SharedStyles";
import { AuthContext } from "../../../context/AuthContext";

interface Baby {
  id: string;
  gender: string;
  date_of_birth: number;
  name: string;
  created_at: number;
  profile_picture: string;
  measurements: {
    weight: number;
    created_at: number;
    height: number;
  }[];
}
interface AccountBabiesProps {
  refresh?: boolean;
}
export const AccountBabies: React.FC<AccountBabiesProps> = ({ refresh }) => {
  const { hasActivePayment } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [babies, setBabies] = useState<Baby[]>([]);

  const fetchBabyDetails = async () => {
    setLoading(true);
    try {
      const response = await getAllBabies();
      setBabies(response.data);
    } catch (error) {
      console.error("Error fetching baby details:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBabyDetails();
  }, []);

  useEffect(() => {
    fetchBabyDetails();
  }, [refresh]);
  return (
    <Container>
      <HeaderText>Children</HeaderText>
      {!loading ? (
        babies?.map((baby, index) => (
          <ListItemWithImage
            key={`i-${index}`}
            itemData={{
              name: baby.name,
              gender: baby.gender,
              age: baby.date_of_birth,
              image: baby.profile_picture,
              id: baby.id,
            }}
            parentComponent="baby-info"
          />
        ))
      ) : (
        <LocalLoadingSpinner />
      )}

      <SharedButton
        iconSrc={addIcon}
        buttonText="Add new baby"
        onClick={() => {
          navigate("/add-baby");
        }}
        $disabled={!hasActivePayment}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
