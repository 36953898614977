import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/style";
import editIcon from "../../../images/editIcon.svg";
import { useBabyContext } from "../../../context/BabyContext";
import { updateBabyData } from "../../../api";
import { uploadImageToStorage } from "../../../common/helpers";
import { ModalOverlay } from "../../shared/SharedStyles";
import { ImageCropper } from "../../miletsones/components/common/CropImage";
import { AuthContext } from "../../../context/AuthContext";

export const ProfilePicture = () => {
  const { hasActivePayment } = React.useContext(AuthContext);
  const { selectedBaby, refreshBabyContext } = useBabyContext();
  const [loading, setLoading] = useState(false);
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);

  const handleEditClick = async () => {
    const inputElement = document.getElementById("imageInput");
    if (inputElement) {
      inputElement.click();
    }
  };

  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    setShowCropperModal(true);
    setUploadedFile(file);
  };

  const handleImageSubmit = async (file: any) => {
    setLoading(true);

    if (file) {
      const uploadedImage = await uploadImageToStorage(file);
      try {
        await updateBabyData(selectedBaby.id, {
          name: selectedBaby.name,
          gender: selectedBaby.gender,
          profile_picture: uploadedImage.imageUrl,
        });
        refreshBabyContext();
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  };

  const croppedImageActionFinished = async (croppedImage: any) => {
    setShowCropperModal(false);
    await handleImageSubmit(croppedImage);
    setUploadedFile(null);
  };

  return (
    <React.Fragment>
      <FirstBorderColor>
        <SecondBorderColor>
          <Container>
            <ProfileImage alt="Baby" src={selectedBaby?.profile_picture} />

            <EditIcon
              alt="Edit Icon"
              src={editIcon}
              onClick={handleEditClick}
              loading={loading}
            />

            <ImageInput
              id="imageInput"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              disabled={loading || !hasActivePayment}
            />
          </Container>
        </SecondBorderColor>
      </FirstBorderColor>
      {showCropperModal && (
        <ModalOverlay>
          <ImageCropper
            image={uploadedFile}
            croppedImageAction={croppedImageActionFinished}
            closeModal={() => {
              setShowCropperModal(!showCropperModal);
              setUploadedFile(null);
            }}
          />
        </ModalOverlay>
      )}
    </React.Fragment>
  );
};

const FirstBorderColor = styled.div`
  position: relative;
  align-self: center;
  border-radius: 50%;
  padding: 48px 24px 8px 24px;
  /* Set the background color with opacity from right to left */
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.6)
  );
`;

const SecondBorderColor = styled.div`
  border-radius: 50%;
  padding: 24px;
  background: rgba(218, 168, 140, 0.2);
`;

const Container = styled.div`
  position: relative;
  width: 210px;
  height: 210px;
  min-height: 210px;
  max-width: 100%;
  @media (max-width: 370px) {
    width: 170px;
    height: 170px;
    min-height: 170px;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid ${colors.white};
  box-shadow: 0px 18px 30px 0px ${colors.warmthGrey};
`;

const EditIcon = styled.img<{ loading: boolean }>`
  position: absolute;
  bottom: -7%;
  left: 50%;
  transform: translateX(-50%);
  width: 36px;
  height: 36px;
  padding: 8px;
  border-radius: 12px;
  background-color: ${(props) =>
    props.loading ? `${colors.white}80` : colors.white};
  cursor: ${(props) => (props.loading ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.loading ? 0.4 : 1)};
`;

const ImageInput = styled.input`
  display: none;
`;
