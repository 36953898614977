import styled from "styled-components";
// import notificationsIcon from "../../../../images/notificationsIcon.svg";

// const HeaderIcon = styled.img`
//   width: 36px;
//   height: 36px;
//   cursor: pointer;
//   align-self: center;
// `;

const HeaderIcon = styled.div`
  width: 36px;
  height: 36px;
  align-self: center;
`;
export const NotificationsIcon = () => {
  // return <HeaderIcon src={notificationsIcon} alt="Notification" />;
  return <HeaderIcon />;
};
