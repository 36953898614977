import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/style";
import { SmallText, SmallestText } from "../../../shared/SharedStyles";
import {
  getResizedImageUrl,
  formatTimestampToDefaultDate,
} from "../../../../common/helpers";
import { imageSizes } from "../../../../common/constants";
import { AuthContext } from "../../../../context/AuthContext";
import { WeAreInMotionModal } from "./WeAreInMotionModal"; // Adjust the import path

interface WeAreInMotionCardProps {
  defaultImage: string;
  databaseImage: string;
  type: string;
  text: string;
  date: number; // Date is epoch time in seconds
  onDataChange: (imageUrl: string, date: number, type: string) => void; // Date is epoch time in seconds
}

export const WeAreInMotionCard = ({
  defaultImage,
  databaseImage,
  type,
  text,
  date,
  onDataChange,
}: WeAreInMotionCardProps) => {
  const { hasActivePayment } = React.useContext(AuthContext);
  // State to manage modal visibility and card data
  const [showModal, setShowModal] = useState(false);
  const [cardData, setCardData] = useState<any>({
    image: databaseImage,
    date: date,
  });

  const handleImageClick = () => {
    if (!hasActivePayment) return; // Check for active payment
    setShowModal(true); // Open the modal when image is clicked
  };

  // Function to handle updates from the modal
  const handleCardUpdate = (updatedImageUrl: string, updatedDate: number) => {
    setCardData({
      ...cardData,
      image: updatedImageUrl,
      date: updatedDate,
    });
    onDataChange(updatedImageUrl, updatedDate, type); // Notify parent component
  };
  return (
    <CardContainer $hasImage={cardData.image ? true : false}>
      <ImageInputContainer>
        <CardImage
          $hasImage={cardData.image ? true : false}
          src={
            cardData.image
              ? getResizedImageUrl(cardData.image, imageSizes.medium)
              : defaultImage
          }
          onError={(e) => {
            e.currentTarget.src = databaseImage;
          }}
          onClick={handleImageClick} // Open modal on image click
        />
        {cardData.image && cardData.date && (
          <DateText>{formatTimestampToDefaultDate(cardData.date)} </DateText>
        )}
      </ImageInputContainer>
      <SmallText>{text}</SmallText>

      {/* Modal Component */}
      {showModal && (
        <WeAreInMotionModal
          showModal={showModal}
          handleSetShowModal={setShowModal}
          cardData={cardData}
          // setCardData={setCardData}
          onCardUpdate={handleCardUpdate}
        />
      )}
    </CardContainer>
  );
};

interface stylesProps {
  $hasImage?: boolean;
}

const CardContainer = styled.div<stylesProps>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${colors.borderWhite};
  justify-content: space-between;
  align-items: center;
  min-height: 210px;
  max-height: 210px;
  background-color: ${(props) =>
    props.$hasImage ? "#f6ede5" : colors.bgWhite};
  padding: ${(props) => (props.$hasImage ? "0 0 30px 0" : "30px 0")};
`;

const CardImage = styled.img<stylesProps>`
  position: ${(props) => (props.$hasImage ? "relative" : "")};
  flex-grow: 1;
  border-radius: ${(props) => (props.$hasImage ? "16px" : "")};
  width: ${(props) => (props.$hasImage ? "100%" : "70%")};
  padding: ${(props) => (props.$hasImage ? "6px" : "0")};
  height: ${(props) => (props.$hasImage ? "130px" : "100%")};
  max-height: ${(props) => (props.$hasImage ? "130px" : "100px")};
`;

const ImageInputContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DateText = styled.p`
  position: absolute;
  bottom: -5%;
  background-color: ${colors.darkBrown};
  border-radius: 8px;
  padding: 3px 6px;
  color: ${colors.white};
  ${SmallestText};
`;
