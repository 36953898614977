import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HeaderText, SmallTextSlim } from "../../shared/SharedStyles";
import { colors } from "../../../styles/style";
import { acceptInvitation, getInvitations } from "../../../api";
import { DeleteAccountConfirmation } from "../../settings/common/DeleteAccountConfirmation";

interface InvitationListProps {
  onRefresh: () => void;
}
export const InvitationList: React.FC<InvitationListProps> = ({
  onRefresh,
}) => {
  const [invitations, setInvitations] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState({
    show: false,
    invitationId: "",
  });
  const [loadingAction, setLoadingAction] = useState(false);

  const fetchInvitations = async () => {
    try {
      const { data } = await getInvitations();
      setInvitations(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchInvitations(); // Initial fetch

    // Set up interval to fetch every 2 seconds
    const intervalId = setInterval(() => {
      fetchInvitations();
    }, 4000); // 2000 ms = 2 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptInvitation = async (
    invitationId: string,
    status: "accept" | "reject"
  ) => {
    setLoadingAction(true);
    try {
      await acceptInvitation({
        invitation_id: invitationId,
        new_status: status,
      });
      onRefresh();
    } catch (error) {
      console.log(error);
    }
    setLoadingAction(false);
  };
  const handleRejectInvitationConfirmed = () => {
    if (showConfirmationModal.invitationId) {
      handleAcceptInvitation(showConfirmationModal.invitationId, "reject");
    }
    setShowConfirmationModal({ show: false, invitationId: "" });
  };
  return (
    <Container>
      <HeaderWrapper>
        <HeaderText>
          {invitations.length > 0 ? "Invitations" : "No active invitations"}
        </HeaderText>
      </HeaderWrapper>
      <DetailsWrapper>
        {invitations?.map((invitation: any) => (
          <DetailsRow key={invitation.id}>
            <SmallTextSlim color={colors.warmthBrown}>
              {invitation.baby.name}
            </SmallTextSlim>
            <IconButtonWrapper>
              <CloseButton
                // onClick={() => handleAcceptInvitation(invitation.id, "reject")}
                onClick={() =>
                  setShowConfirmationModal({
                    ...showConfirmationModal,
                    show: true,
                    invitationId: invitation.id,
                  })
                }
                style={{ color: colors.darkBrown }}
                disabled={loadingAction}
              >
                <CloseIcon
                  className="bi bi-x-lg"
                  style={{ color: colors.darkBrown }}
                ></CloseIcon>
              </CloseButton>
              <CloseButton
                onClick={() => {
                  handleAcceptInvitation(invitation.id, "accept");
                }}
                style={{ color: colors.darkBrown }}
                disabled={loadingAction}
              >
                <CloseIcon className="bi bi-check2-square"></CloseIcon>
              </CloseButton>
            </IconButtonWrapper>
            {showConfirmationModal && (
              <DeleteAccountConfirmation
                showModal={showConfirmationModal.show}
                handleSetShowModal={() =>
                  setShowConfirmationModal({ show: false, invitationId: "" })
                }
                title="Just Checking!"
                handleActionClick={handleRejectInvitationConfirmed}
                loading={loadingAction}
                paragraphText="By rejecting the invitation, you won’t have access to the baby profile."
                headerText="Are you sure you want to go ahead?"
              />
            )}
          </DetailsRow>
        ))}
      </DetailsWrapper>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0 0 0;
  gap: 12px;
  margin: 36px 0px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${colors.borderWhite};
`;

const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px; /* Adjust the gap as per your requirement */
`;

const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const CloseIcon = styled.i`
  font-size: 24px;
`;
