import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../styles/style";
import goBackIcon from "../../../images/goBackIcon.svg";
import Button from "../../../helpers/components/Button";
import {
  BigHeaderText,
  ExtraSmallIcon,
  FlexRowBetweenCenter,
  MediumIcon,
  ModalOverlay,
  SmallIcon,
  SmallText,
} from "../../shared/SharedStyles";
import { useNavigate } from "react-router-dom";
import addIcon from "../../../images/addIcon.svg";
import { AddTextModal } from "./common/AddTextModal";
import {
  addFirstBirthdayData,
  getFirstBirthdayData,
  updateFirstBirthdayData,
} from "../../../api";
import { useBabyContext } from "../../../context/BabyContext";
import { firstBirthdayNode } from "../../../common/constants";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { ImageCropper } from "./common/CropImage";
import { uploadImageToStorage } from "../../../common/helpers";
import firstBirthday from "../../../images/firstBirthday.png";
import {
  MilestoneButtonWrapper,
  MilestoneClickerScriptText,
  MilestoneSelectedImage,
  MilestoneWrapper,
} from "../common/SharedComponents";
import { AuthContext } from "../../../context/AuthContext";

export const FirstBirthday = () => {
  const navigate = useNavigate();
  const { hasActivePayment } = React.useContext(AuthContext);
  const goBackHandler = () => {
    navigate(-1);
  };
  const [loading, setLoading] = useState(false);
  const { selectedBabyId } = useBabyContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstBirthdayData, setFirstBirthdayData] =
    useState<any>(firstBirthdayNode);
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [selectedPosition, setSelectedPosition] = useState<number | null>(null);

  const handleTextSubmit = async (text?: string) => {
    setLoading(true);
    if (firstBirthdayData.id) {
      await updateFirstBirthdayData(selectedBabyId, {
        baby_id: selectedBabyId,
        images: [...firstBirthdayData.images],
        text: text,
      });
    } else {
      await addFirstBirthdayData({
        baby_id: selectedBabyId,
        text: text,
        images: [],
      });
    }
    fetchFirstYearData();
    setLoading(false);
  };

  const handleImageUpload = async (file: any, position: number) => {
    setLoading(true);
    const uploadedImage = await uploadImageToStorage(
      file,
      "images/milestones/first-birthday/"
    );

    if (firstBirthdayData.id) {
      const existingImageIndex = firstBirthdayData.images.findIndex(
        (image: any) => image.position === position
      );
      if (existingImageIndex !== -1) {
        // If an image with the same position exists, update its URL
        const updatedImages = [...firstBirthdayData.images];
        updatedImages[existingImageIndex].url = uploadedImage.imageUrl;

        await updateFirstBirthdayData(selectedBabyId, {
          baby_id: selectedBabyId,
          images: updatedImages,
          text: firstBirthdayData.text,
        });
      } else {
        await updateFirstBirthdayData(selectedBabyId, {
          baby_id: selectedBabyId,
          images: [
            ...firstBirthdayData.images,
            { position: position, url: uploadedImage.imageUrl },
          ],
          text: firstBirthdayData.text,
        });
      }
    } else {
      await addFirstBirthdayData({
        baby_id: selectedBabyId,
        images: [{ position: position, url: uploadedImage.imageUrl }],
        text: "",
      });
    }
    fetchFirstYearData();
    setLoading(false);
  };

  const fetchFirstYearData = async () => {
    setLoading(true);
    await getFirstBirthdayData(selectedBabyId)
      .then(({ data }) => {
        setFirstBirthdayData({
          id: data[0].id,
          text: data[0].text,
          images: [...data[0].images],
        });
      })
      .catch((err) => {});

    setLoading(false);
  };

  useEffect(() => {
    fetchFirstYearData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderImage = (position: number) => {
    const image = firstBirthdayData.images?.find(
      (image: any) => image.position === position
    );
    return image ? image.url : "";
  };

  const handleImageChange = async (event: any, position: number) => {
    const file = event.target.files[0];
    if (!file) return;
    setSelectedPosition(position);
    setShowCropperModal(true);
    setUploadedFile(file);
  };

  const croppedImageActionFinished = async (croppedImage: any) => {
    setShowCropperModal(false);
    if (selectedPosition === null) return;
    await handleImageUpload(croppedImage, selectedPosition as number);
    setUploadedFile(null);
  };

  const ImageInput = ({ onChange, imageUrl, width, height, disabled }: any) => (
    <ImageInputContainer
      $width={width ? width : "150px"}
      $height={height ? height : "150px"}
      $disabled={disabled}
    >
      <input
        type="file"
        accept="image/*"
        onChange={onChange}
        style={{ display: "none" }}
        disabled={!hasActivePayment}
      />
      {imageUrl ? (
        <MilestoneSelectedImage src={imageUrl} />
      ) : (
        <SmallIcon src={addIcon} />
      )}
    </ImageInputContainer>
  );

  return (
    <React.Fragment>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Container>
          <HeaderContainer>
            <MediumIcon src={goBackIcon} onClick={goBackHandler} />
            <BigHeaderText>My First Birthday</BigHeaderText>
            <div></div>
          </HeaderContainer>
          <MilestoneWrapper>
            <HolidayWrapper>
              {firstBirthdayData.text ? (
                <MilestoneClickerScriptText
                  onClick={() => {
                    if (hasActivePayment) {
                      setIsModalOpen(true);
                    }
                  }}
                >
                  {firstBirthdayData.text}
                </MilestoneClickerScriptText>
              ) : (
                <ButtonContainer
                  onClick={() => {
                    if (hasActivePayment) {
                      setIsModalOpen(true);
                    }
                  }}
                >
                  <ImageInputContainer $width="38px" $height="38px">
                    <ExtraSmallIcon src={addIcon} />
                  </ImageInputContainer>
                  <SmallText>Write some text</SmallText>
                </ButtonContainer>
              )}
              <FirstRow>
                <ImageInput
                  onChange={(event: any) => handleImageChange(event, 0)}
                  disabled={renderImage(0) === "" && !hasActivePayment}
                  imageUrl={
                    renderImage(0)
                    // &&
                    // getResizedImageUrl(renderImage(0), imageSizes.large)
                  }
                  onError={(e: any) => {
                    e.currentTarget.src = renderImage(0);
                  }}
                  width="200px"
                  height="200px"
                />
              </FirstRow>
              <SecondRow>
                {[1, 2].map((position) => (
                  <ImageInput
                    key={position}
                    onChange={(event: any) =>
                      handleImageChange(event, position)
                    }
                    disabled={renderImage(position) === "" && !hasActivePayment}
                    imageUrl={
                      renderImage(position)
                      // &&
                      // getResizedImageUrl(
                      //   renderImage(position),
                      //   imageSizes.large
                      // )
                    }
                    onError={(e: any) => {
                      e.currentTarget.src = renderImage(position);
                    }}
                  />
                ))}
              </SecondRow>
            </HolidayWrapper>
          </MilestoneWrapper>
          <MilestoneButtonWrapper>
            <Button $button_type="colored" onClick={goBackHandler}>
              Go Back
            </Button>
          </MilestoneButtonWrapper>
        </Container>
      )}
      {isModalOpen && (
        <AddTextModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleTextSubmit}
          initialText={firstBirthdayData.text}
          parentComponent="firstBirthdayPage"
        />
      )}
      {showCropperModal && (
        <ModalOverlay>
          <ImageCropper
            image={uploadedFile}
            croppedImageAction={croppedImageActionFinished}
            closeModal={() => {
              setShowCropperModal(!showCropperModal);
              setUploadedFile(null);
            }}
          />
        </ModalOverlay>
      )}
    </React.Fragment>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  padding-top: 40px;
  background-color: ${colors.rosewaterMist};
  overflow-y: scroll;
  background-image: url(${firstBirthday});
  background-size: cover;
  background-size: contain;
`;

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const HolidayWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin: 20px 20px;
  gap: 50px;
`;

const ButtonContainer = styled.div`
  width: fit-content;
  border-radius: 16px;
  background-color: ${colors.softGrey};
  padding: 12px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

interface ImageInputContainerProps {
  $width?: string;
  $height?: string;
  $disabled?: boolean;
}
const ImageInputContainer = styled.label<ImageInputContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  max-width: 100%;
  max-width: 100%;
  border: 2px solid ${colors.white};
  border-radius: 50%;
  background-color: ${colors.lightPeach};
  box-shadow: 0px 1.348px 14.149px 3.369px rgba(153, 158, 216, 0.2);
  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`;

const FirstRow = styled.div`
  background-color: rgba(255, 247, 242, 0.62);
  border-radius: 50%;
  padding: 24px;
`;

const SecondRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
