import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/style";

interface EditModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const EditModal: React.FC<EditModalProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsAnimating(true);
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape" && isOpen) {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen, onClose]);

  const handleClickOutside = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      {isOpen && (
        <Overlay onClick={handleClickOutside}>
          <ModalContent $isAnimating={isAnimating}>{children}</ModalContent>
        </Overlay>
      )}
    </>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 100;
`;

interface ModalContentProps {
  $isAnimating: boolean;
}

const ModalContent = styled.div<ModalContentProps>`
  max-height: 90vh;
  overflow-y: auto;
  width: 100%;
  background-color: ${colors.white};
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 24px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  transform: translateY(${(props) => (props.$isAnimating ? "0" : "100%")});
  transition: transform 0.2s ease-in-out;
`;
