import React, { ReactNode } from "react";
import styled from "styled-components";

interface SettingsCardProps {
  children: ReactNode;
  margin?: string; // New prop for margin
}

export const ProfileCard: React.FC<SettingsCardProps> = ({
  children,
  margin,
}) => {
  // @ts-ignore
  const isNative = window?.ReactNativeWebView;
  return (
    <Container style={{ paddingTop: isNative && "30px" }}>
      <MainWrapper margin={margin}>{children}</MainWrapper>
    </Container>
  );
};

const Container = styled.div`
  /* display: flex; */
  height: 100vh;
  max-height: 100%;
  min-height: 100vh;
  overflow-y: auto;
`;

const MainWrapper = styled.div<SettingsCardProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 116px; */
  /* max-height: 100%; */
`;
