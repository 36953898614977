// Post.tsx
import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/style";
import { formatTimestampToDate } from "../../../common/helpers";
import { SmallTextConstant, SmallestText } from "../../shared/SharedStyles";
import { ImageGalleryModal } from "./ImageGalleryModal";
import { getResizedImageUrl } from "../../../common/helpers";
import { imageSizes } from "../../../common/constants";

interface PostProps {
  username: string;
  date: number;
  images: string[];
}

interface PostImagesContainerProps {
  $single_image: string;
}

export const Post: React.FC<PostProps> = ({ username, date, images }) => {
  const $singleImage = images.length === 1;
  const moreThanFourImages = images.length > 4;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <PostContainer>
        <PostInfo>
          <Username>
            • {username} added{" "}
            {`${images.length} ${images.length === 1 ? "image" : "images"}`}
          </Username>
          <DateText>{formatTimestampToDate(date)}</DateText>
        </PostInfo>
        <PostImagesContainer
          onClick={() => setShowModal(true)}
          $single_image={$singleImage.toString()}
        >
          {moreThanFourImages
            ? images.slice(0, 3).map((imageUrl, index) => (
                <Image
                  sizes="auto"
                  loading="lazy"
                  key={index}
                  src={getResizedImageUrl(imageUrl, imageSizes.medium)}
                  alt={`Posted content ${index + 1}`}
                  onError={(e) => {
                    e.currentTarget.src = imageUrl;
                  }}
                />
              ))
            : images.length === 1
              ? images.slice(0, 4).map((imageUrl, index) => (
                  <MainImage
                    loading="lazy"
                    key={index}
                    src={getResizedImageUrl(imageUrl, imageSizes.large)}
                    alt={`Posted content ${index + 1}`}
                    onError={(e) => {
                      e.currentTarget.src = imageUrl;
                    }}
                  />
                ))
              : images.slice(0, 4).map((imageUrl, index) => (
                  <Image
                    loading="lazy"
                    key={index}
                    src={getResizedImageUrl(imageUrl, imageSizes.medium)}
                    alt={`Posted content ${index + 1}`}
                    onError={(e) => {
                      e.currentTarget.src = imageUrl;
                    }}
                  />
                ))}
          {moreThanFourImages && (
            <ImageWithTextOverlay>
              <Image
                key="4"
                src={getResizedImageUrl(images[3], imageSizes.medium)}
                alt="Posted content 4"
                onError={(e) => {
                  e.currentTarget.src = images[3];
                }}
              />
              <TextOverlay>+ {images.length - 4} more</TextOverlay>
            </ImageWithTextOverlay>
          )}
        </PostImagesContainer>
      </PostContainer>
      {showModal && (
        <ImageGalleryModal
          images={images}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const PostInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const Username = styled.h3`
  color: ${colors.softBrown};
  ${SmallestText};
`;

const DateText = styled.p`
  color: ${colors.warmthBrown};
  ${SmallestText};
`;

const Image = styled.img`
  width: 161px;
  max-width: 100%;
  max-height: 91px;
  border-radius: 8px;
  border: 0px solid #fff;
`;
const MainImage = styled.img`
  width: 330px;
  max-width: 100%;
  height: 190px;
  border-radius: 24px;
  border: 0px solid #fff;
  box-shadow: 0px 18px 30px 0px rgba(0, 0, 0, 0.1);
`;

const PostImagesContainer = styled.div<PostImagesContainerProps>`
  padding: 0 4px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.$single_image === "true" ? "1fr" : "repeat(2, 1fr)"};
  grid-gap: 8px;

  @media (min-width: 400px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const ImageWithTextOverlay = styled.div`
  position: relative;
`;
const TextOverlay = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  color: ${colors.white};
  ${SmallTextConstant};
`;
