import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/style";
import goBackIcon from "../../../../images/goBackIcon.svg";
import Button from "../../../../helpers/components/Button";
import {
  BigHeaderText,
  FlexRowBetweenCenter,
  MediumIcon,
  SmallIcon,
} from "../../../shared/SharedStyles";
import { EditModal } from "../../../shared/EditModal";
import { SharedButton } from "../../../settings/common/shared/SharedButton";
import cameraIcon from "../../../../images/cameraIconBlack.svg";
import penIcon from "../../../../images/penIconBlack.svg";
import remove_image_icon from "../../../../images/remove-image-icon.svg";
import { CustomTextArea } from "../../../../helpers/components/CustomInputs";
import {
  getResizedImageUrl,
  uploadImageToStorage,
} from "../../../../common/helpers";
import { addMonthlyDiary, updateMonthlyDiary } from "../../../../api";
import { useBabyContext } from "../../../../context/BabyContext";
import Select from "react-select";
import { imageSizes, months } from "../../../../common/constants";

interface AddNewDiaryModalProps {
  showModal: boolean;
  handleSetShowModal: React.Dispatch<React.SetStateAction<any>>;
  dataToAddOrUpdateFromParent: any; // Adjust the type as needed
  monthlyDiaryData: any;
  handleRefreshParentData: () => void;
  parentComponentName: "monthlyDiary" | "familyTree";
}

export const AddNewDiaryModal: React.FC<AddNewDiaryModalProps> = ({
  showModal,
  handleSetShowModal,
  dataToAddOrUpdateFromParent,
  handleRefreshParentData,
  parentComponentName,
  monthlyDiaryData,
}) => {
  const { selectedBabyId } = useBabyContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState({ text: false, image: false });
  const [modalData, setModalData] = useState<any>(null);
  const selectedImageInputRef = useRef<HTMLInputElement>(null);

  const [filteredMonths, setFilteredMonths] = useState(months);

  useEffect(() => {
    if (monthlyDiaryData) {
      const filtered = months.filter((month) => {
        return !monthlyDiaryData.some(
          (data: any) => data.month === month.value
        );
      });
      setFilteredMonths(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyDiaryData]);

  const handleAddPhoto = () => {
    selectedImageInputRef.current?.click();
  };

  useEffect(() => {
    setModalData(dataToAddOrUpdateFromParent);
  }, [dataToAddOrUpdateFromParent]);
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedImageFile = e.target.files?.[0];
    if (selectedImageFile) {
      setModalData({
        ...modalData,
        imageFile: selectedImageFile,
        isUpdated: !modalData?.isUpdated ? true : modalData?.isUpdated,
      });
    }
  };

  async function uploadMilestoneImage(
    dataToAddOrUpdate: any,
    setModalData: React.Dispatch<any>
  ) {
    const uploadedImage = await uploadImageToStorage(
      dataToAddOrUpdate?.imageFile,
      "images/milestones/monthly-diary/"
    );
    setModalData({
      ...dataToAddOrUpdate,
      image: uploadedImage.imageUrl,
    });
    return uploadedImage.imageUrl;
  }

  const handleSubmitData = async () => {
    setLoading(true);
    const diaryData = modalData;
    if (parentComponentName === "monthlyDiary") {
      await handleSubmitNewDiaryData(diaryData);
    }
    setModalData(null);
    setLoading(false);
  };
  const handleSubmitNewDiaryData = async (diaryData: any) => {
    if (modalData?.id) {
      //patch
      if (modalData?.imageFile) {
        const uploadedImageUrl = await uploadMilestoneImage(
          modalData,
          setModalData
        );
        diaryData.image = uploadedImageUrl;
      }
      await updateMonthlyDiary({
        baby_id: selectedBabyId,
        year: diaryData.year,
        month: diaryData.month,
        image: diaryData.image,
        text: diaryData.text,
      })
        .then((res) => {
          handleRefreshParentData();
          handleSetShowModal(false);
        })
        .catch(() => {});
    } else {
      if (modalData?.imageFile) {
        const uploadedImageUrl = await uploadMilestoneImage(
          modalData,
          setModalData
        );
        diaryData.image = uploadedImageUrl;
      }
      await addMonthlyDiary({
        baby_id: selectedBabyId,
        year: diaryData.year,
        month: diaryData.month,
        image: diaryData.image,
        text: diaryData.text,
      })
        .then((res) => {
          handleRefreshParentData();
          handleSetShowModal(false);
        })
        .catch((err) => {});
    }
  };

  const handleCloseModal = () => {
    handleSetShowModal(false);
    setModalData(null);
  };
  return (
    <EditModal isOpen={showModal} onClose={handleCloseModal}>
      <HeaderContainer>
        <MediumIcon src={goBackIcon} onClick={handleCloseModal} />
        <BigHeaderText>
          {parentComponentName === "familyTree"
            ? modalData?.text
              ? "Update Tree Entry"
              : "New Tree Entry"
            : modalData?.text
              ? "Update Diary Entry"
              : "New Diary Entry"}
        </BigHeaderText>
        <div></div>
      </HeaderContainer>
      <ButtonsWrapper>
        {!modalData?.image && (
          <Select
            isSearchable={false}
            value={filteredMonths.find(
              (option) => option.value === modalData?.month
            )}
            onChange={(selectedOption) => {
              setModalData({
                ...modalData,
                month: selectedOption?.value,
                isUpdated: !modalData.isUpdated ? true : modalData.isUpdated,
              });
            }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                width: "100%",
                height: "54px",
                padding: "8px",
                borderRadius: "16px",
                border: `2px solid ${
                  state.isFocused ? colors.warmthBrown : colors.borderWhite
                }`,
                backkgroundColor: "white",
                boxShadow: "none",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? colors.softBrown : "white",
              }),
            }}
            options={filteredMonths}
          />
        )}
        {modalData?.imageFile || modalData?.image ? (
          <SelectedInputImageContainer>
            <SelectedInputImage
              src={
                modalData?.imageFile
                  ? URL.createObjectURL(modalData?.imageFile)
                  : getResizedImageUrl(modalData?.image, imageSizes.medium)
              }
              alt="Selected"
            />
            <SmallIcon
              src={remove_image_icon}
              alt="Remove"
              onClick={() => {
                setModalData({
                  ...modalData,
                  imageFile: null,
                  image: null,
                });
              }}
            />
          </SelectedInputImageContainer>
        ) : (
          <SharedButton
            iconSrc={cameraIcon}
            buttonText="Add a photo"
            onClick={handleAddPhoto}
          />
        )}
        {isEditing.text || modalData?.text ? (
          <CustomTextArea
            label=""
            placeholder="Write some text"
            value={modalData?.text}
            onChange={(e) =>
              setModalData({
                ...modalData,
                text: e.target.value,
                isUpdated: !modalData.isUpdated ? true : modalData.isUpdated,
              })
            }
          />
        ) : (
          <SharedButton
            iconSrc={penIcon}
            buttonText={"Write some text"}
            onClick={() => setIsEditing({ ...isEditing, text: true })}
          />
        )}

        {(isEditing.text || modalData?.text) && (
          <SharedButton
            iconSrc={penIcon}
            buttonText={"Done"}
            onClick={handleSubmitData}
            $disabled={
              loading ||
              !modalData?.text ||
              (!modalData?.image && !modalData?.imageFile)
            }
            loading={loading}
          />
        )}
        <Button $button_type="colored" onClick={handleCloseModal}>
          Cancel
        </Button>
      </ButtonsWrapper>

      <ImageInput
        type="file"
        accept="image/*"
        ref={selectedImageInputRef}
        onChange={handleImageChange}
      />
    </EditModal>
  );
};

const ImageInput = styled.input`
  display: none;
`;
const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const SelectedInputImage = styled.img`
  width: 144px;
  height: 78px;
  flex-shrink: 0;
  border-radius: 12px;
`;
const SelectedInputImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  border-radius: 16px;
  border: 1px solid #d9dcdf;
  padding: 8px;
`;
