export const spacing = {
  xs: "6px",
  sm: "8px",
  md: "16px",
  lg: "32px",
  xl: "64px",
  xl2: "128px",
};
export const fontSize = {
  xs: "6px",
  sm: "8px",
  md: "16px",
  lg: "32px",
  xl: "64px",
  xl2: "128px",
};
export const colors = {
  warmthBrown: "#7a5038",
  darkBrown: "#482714",
  softBrown: "#cd8f6c",
  white: "#fff",
  bgWhite: "#ffffffcc",
  borderWhite: "#E8DCDC",
  deepBrown: "#7e5640",
  coralPink: "#dc6c79", //birth-certificate
  mistyRoseColor: "#e5909b", //birth-certificate
  darkGrey: "#999ed833", //baby image header shadow color
  softOrange: "#f0615b",
  softGrey: "#daa88c33",
  warmthGrey: "#0000001a",
  whiteBackgroundColor: "#f6ede5",
  filledWhiteBackgroundColor: "#ffd4bc",
  red: "#FF0000",
  softPink: "#ffe1d0",
  warmthPink: "#daa88c",
  lightGrey: "#f0f0f0",
  lightPeach: "#f7ecdf",
  rosewaterMist: "#FFF2F0",
  green: "green",
};

export const borderRadius = "4px";
export const buttonsBorderRadius = "39px";

//Screen sizes
export const breakpoints = {
  XSMALL: "320px",
  SMALL: "600px",
  MEDIUM: "768px",
  LARGE: "992px",
  XLARGE: "1200px",
};
