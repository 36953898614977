import React, { useState, useCallback, FunctionComponent } from "react";
import Cropper from "react-easy-crop";
import styled from "styled-components";
import { getCroppedImgNew } from "../../../../common/helpers";
import Button from "../../../../helpers/components/Button";

const CropperContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 12px;
`;

type Props = {
  userId?: string;
  image: any;
  croppedImageAction: (image: any) => void;
  closeModal: () => void;
};

export const ImageCropper: FunctionComponent<Props> = ({
  image,
  croppedImageAction,
  closeModal,
}) => {
  const imageSrc = URL.createObjectURL(image);
  const [crop, setCrop] = useState({ x: 2, y: 2 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(2);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const showCroppedImage = useCallback(async () => {
    setSaveButtonLoading(true);
    try {
      const croppedImage: any = await getCroppedImgNew(
        imageSrc,
        croppedAreaPixels,
        rotation,
        image.name
      );
      handleSave(croppedImage, image.name);
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSrc, croppedAreaPixels, rotation, image.name]);

  const handleSave = async (croppedImage: any, fileName: string) => {
    croppedImageAction(croppedImage);
  };

  return (
    <>
      <CropperContainer>
        <Cropper
          image={URL.createObjectURL(image)}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          cropSize={{ width: 400, height: 400 }}
          aspect={1}
          cropShape="round"
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          restrictPosition={false}
        />
      </CropperContainer>
      <ButtonsWrapper style={{ gap: "10px" }}>
        <Button $button_type="primary" onClick={() => closeModal()}>
          Close
        </Button>
        <Button
          $button_type="colored"
          onClick={() => showCroppedImage()}
          disabled={saveButtonLoading}
          loading={saveButtonLoading}
        >
          Save
        </Button>
      </ButtonsWrapper>
    </>
  );
};
