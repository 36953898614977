import React from "react";
import styled from "styled-components";
import goBackIcon from "../../../images/goBackIcon.svg";
import Button from "../../../helpers/components/Button";
import { EditModal } from "../../shared/EditModal";
import { SharedButton } from "./shared/SharedButton";
import {
  BigHeaderText,
  FlexRowBetweenCenter,
  HeaderText,
  SmallText,
} from "../../shared/SharedStyles";
import deleteIcon from "../../../images/deleteIcon.svg";

interface DeleteAccountConfirmationProps {
  showModal: boolean;
  handleSetShowModal: () => void;
  handleActionClick: () => void;
  title: string;
  loading: boolean;
  paragraphText?: string;
  headerText?: string;
}

export const DeleteAccountConfirmation: React.FC<
  DeleteAccountConfirmationProps
> = ({
  showModal,
  handleSetShowModal,
  handleActionClick,
  title,
  loading,
  paragraphText,
  headerText,
}) => {
  return (
    <EditModal isOpen={showModal} onClose={handleSetShowModal}>
      <HeaderContainer>
        <HeaderIcon src={goBackIcon} onClick={handleSetShowModal} />
        <BigHeaderText>{title}</BigHeaderText>
        <div></div>
      </HeaderContainer>
      <ConfirmationTextWrapper>
        <HeaderText>{headerText}</HeaderText>
        <SmallText>{paragraphText}</SmallText>
      </ConfirmationTextWrapper>
      <ButtonsWrapper>
        <SharedButton
          iconSrc={deleteIcon}
          buttonText={"Delete"}
          onClick={() => handleActionClick()}
          $disabled={loading}
          loading={loading}
        />
        <Button $button_type="colored" onClick={handleSetShowModal}>
          Cancel
        </Button>
      </ButtonsWrapper>
    </EditModal>
  );
};

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px 0;
  width: 100%;
`;

const HeaderIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ConfirmationTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
`;
