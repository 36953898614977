import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import addIcon from "../../../images/addIcon.svg";
import { getBabyProfileContributors } from "../../../api";
import { LocalLoadingSpinner } from "../../../common/LoadingSpinner";
import { SmallTextColorProps } from "../../shared/SharedStyles";
import { ListItemWithImage } from "../../settings/common/shared/ListItemWithImage";
import { SharedButton } from "../../settings/common/shared/SharedButton";
import { useBabyContext } from "../../../context/BabyContext";
import { appImages } from "../../../common/constants";
import { colors } from "../../../styles/style";
import { AddTextModal } from "../../miletsones/components/common/AddTextModal";
import { AuthContext } from "../../../context/AuthContext";
import { capitalizeFirstLetter } from "../../../common/helpers";

interface UserInterface {
  id: string;
  email: string;
  name: string;
}

interface BabyUserInterface {
  baby_id: string;
  user_id: string;
  created_at: number;
  relation: string;
  user: UserInterface;
  entityType: "collaborator" | "invitation";
  receiver_email: string;
}
export const ProfileContributors = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [contributors, setContributors] = useState<BabyUserInterface[]>([]);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const { selectedBabyId } = useBabyContext();
  const authContext = useContext(AuthContext);
  const { authUser } = authContext;

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await getBabyProfileContributors(
        selectedBabyId as string
      );
      setContributors(data);
    } catch (error) {
      console.error("Error fetching baby details:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adminUser = contributors?.find(
    // eslint-disable-next-line eqeqeq
    (c) => c.relation == "0" || c.relation === "admin"
  );
  const isAdmin = adminUser?.user_id === authUser?.id;
  return (
    <Container>
      <TitleText $color={colors.darkBrown} style={{ marginBottom: "16px" }}>
        Profile Contributors
      </TitleText>
      {!loading ? (
        contributors?.map((contributor, index) => (
          <ListItemWithImage
            key={`i-${index}`}
            itemData={{
              name:
                contributor.entityType === "collaborator"
                  ? contributor.user?.name
                  : contributor.receiver_email,

              email:
                contributor.entityType === "collaborator"
                  ? contributor.user.email
                  : "",
              descriptionText:
                contributor.entityType === "invitation"
                  ? `Invited as ${capitalizeFirstLetter(contributor.relation)}`
                  : contributor.relation.toString() === "0"
                    ? "Admin"
                    : contributor.relation,
              image: appImages.blankProfilePicture,
              id: contributor.user_id,
              userId: contributor.user_id,
              babyId: contributor.baby_id,
              deleteAllowed:
                !isAdmin || contributor.entityType === "invitation"
                  ? false
                  : true,
              entity: contributor.entityType,
            }}
            parentComponent="profile-contributors"
            onRefresh={() => fetchData()}
          />
        ))
      ) : (
        <LocalLoadingSpinner />
      )}

      <SharedButton
        iconSrc={addIcon}
        buttonText="Add new"
        onClick={() => {
          setShowAddModal(true);
        }}
      />
      {showAddModal && (
        <AddTextModal
          isOpen={showAddModal}
          onClose={() => setShowAddModal(false)}
          onSubmit={() => setShowAddModal(false)}
          initialText={"Add new contributor"}
          parentComponent="profileContributors"
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  gap: 16px;
`;

const TitleText = styled.h2<SmallTextColorProps>`
  color: ${(props) => props.$color};
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.3px;
  align-self: flex-start; /* Align title to the left */
  margin-top: 15px;
  margin-bottom: 2px;
`;
