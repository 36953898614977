import { useBabyContext } from "../../context/BabyContext";
import { NavigationMenu } from "../shared/NavigationMenu";
import { FeedCard } from "./common/FeedCard";
import { FeedHeader } from "./common/FeedHeader";
import { MilestoneList } from "./common/MilestoneList";
import { Posts } from "./common/Posts";

export const FeedPage = () => {
  const { selectedBaby } = useBabyContext();
  return (
    <FeedCard>
      <FeedHeader />
      {selectedBaby && <MilestoneList />}
      <Posts />
      <NavigationMenu />
    </FeedCard>
  );
};
