import styled from "styled-components";
import { colors } from "../../../styles/style";
import { MilestoneCard } from "../../feed/common/MilestoneCard";
import { useNavigate } from "react-router-dom";
import { HeaderText } from "../../shared/SharedStyles";
import { milestonesData } from "../../../common/constants";

export const AllMilestones = () => {
  const navigate = useNavigate();

  const handleClick = (url: string) => {
    navigate(url);
  };
  return (
    <Container>
      <ContainerHeader>
        <HeaderText>All Milestones</HeaderText>
      </ContainerHeader>
      <AllMilestonesContainer>
        {milestonesData.map((milestone, index) => (
          <MilestoneCard
            key={index}
            $icon_src={milestone.iconSrc}
            title={milestone.title}
            width="auto"
            $max_width=""
            $min_width="100px"
            onClick={() => handleClick(milestone.url)}
            $bgColor={milestone.url === "" ? colors.lightGrey : ""}
          />
        ))}
      </AllMilestonesContainer>
    </Container>
  );
};

const Container = styled.div`
  flex-grow: 1;
  padding: 24px;
  border-radius: 32px;
  background: ${colors.bgWhite};
  padding-bottom: 100px;
`;
const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;
const AllMilestonesContainer = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
  gap: 12px;
`;
