import React, { useEffect } from "react";
import AppRoutes from "./routes/AppRoutes";
import { useBabyContext } from "./context/BabyContext";

function App() {
  const { updateDevice } = useBabyContext();

  useEffect(() => {
    // @ts-ignore
    const isNative = window?.ReactNativeWebView;
    if (isNative) {
      // @ts-ignore
      const notificationType = window._modelName;
      // @ts-ignore
      updateDevice(notificationType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AppRoutes />;
}

export default App;
