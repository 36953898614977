import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/style";
import { NavigationMenu } from "../shared/NavigationMenu";
import { ProfileEditPagePicture } from "./common/ProfileEditPagePicture";
import { ProfileName } from "./common/ProfileName";
import {
  IDetails,
  InformationSection,
} from "../settings/common/InformationSection";

const babyPersonalInofrmation: IDetails[] = [
  { title: "Place of Birth", value: "No Data" },
  { title: "Date of Birth", value: "No Data" },
  // { title: "Mother", value: "Amanda" },
  // { title: "Father", value: "Bekon" },
];

export const ProfileEditPage = () => {
  return (
    <React.Fragment>
      <ModalContent>
        <ProfileEditPagePicture />
        <ProfileName $align_items="normal" $margin="42px 0px 36px 0px" />
        {/* <ProfileContributors /> */}
        <InformationSection
          title="Your personal information"
          data={babyPersonalInofrmation}
        />
      </ModalContent>
      <NavigationMenu />
    </React.Fragment>
  );
};

const ModalContent = styled.div`
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  background-color: ${colors.white};
  padding: 34px 24px 104px 24px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;
