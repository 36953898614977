import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles/style";
import { useBabyContext } from "../../../context/BabyContext";
import { BigText, SmallText } from "../../shared/SharedStyles";
import { formatLongDate } from "../../../common/helpers";

interface ContainerProps {
  $align_items?: string;
  $margin?: string;
}

export const ProfileName: React.FC<ContainerProps> = ({
  $align_items = "center",
  $margin,
}) => {
  const { selectedBaby } = useBabyContext();
  return (
    <Container $align_items={$align_items} $margin={$margin}>
      <BigText $color={colors.darkBrown}>
        {selectedBaby && selectedBaby.name}
      </BigText>
      <SmallText $color={colors.softBrown}>
        {formatLongDate(selectedBaby.date_of_birth)}
      </SmallText>
    </Container>
  );
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.$align_items || "center"};
  gap: 4px;
  margin: ${(props) => props.$margin || "0px 16px 36px 16px"};
`;
