import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../helpers/components/Button";
// import leftIcon from "../../images/leftIcon.svg";
// import rightIcon from "../../images/rightIcon.svg";
import deleteIcon from "../../images/deleteIcon.svg";
import { useBabyContext } from "../../context/BabyContext";
import { addPost } from "../../api";
import { uploadImageToStorage } from "../../common/helpers";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MediumIcon, ModalOverlay } from "./SharedStyles";

interface AddPostModalProps {
  imageFiles: File[];
  onClose: () => void;
}

const AddPostModal: React.FC<AddPostModalProps> = ({ imageFiles, onClose }) => {
  const { selectedBabyId, setPostAdded } = useBabyContext();
  const [loading, setLoading] = useState(false);
  const [imageFilesState, setImageFilesState] = useState<File[]>(imageFiles);
  const [currentIndex, setCurrentIndex] = useState(0);

  const onDeleteImage = () => {
    const newImageFiles = [...imageFilesState];
    const newImageCount = newImageFiles.length;
    newImageFiles.splice(currentIndex, 1);

    // Update the state with the new imageFiles array
    setImageFilesState(newImageFiles);

    // If there are still images, adjust currentIndex
    if (newImageCount > 1) {
      const adjustedIndex = currentIndex > 0 ? currentIndex - 1 : 0;
      setCurrentIndex(adjustedIndex);
    } else {
      // If there are no images left, handle it appropriately (e.g., close the modal)
      onClose();
    }
  };

  const handleCreatePost = async () => {
    if (loading) return;
    setLoading(true);

    // Upload all selected images
    const uploadedImageUrls = await Promise.all(
      imageFilesState.map(async (imageFile) => {
        return await uploadImageToStorage(imageFile);
      })
    );
    const imageUrls = uploadedImageUrls.map(
      (uploadedImage) => uploadedImage.imageUrl
    );

    await addPost({
      file_references: imageUrls,
      baby_id: selectedBabyId ?? "NrTI6s9ukwBqgpfrGLFF",
    })
      .then(() => {
        setPostAdded((prevPostAdded) => !prevPostAdded);
        onClose();
      })
      .catch((error) => {});

    setLoading(false);
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <Carousel
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          onChange={(index) => setCurrentIndex(index)}
          selectedItem={currentIndex}
          // infiniteLoop={true}
        >
          {imageFilesState.map((imageFile, index) => (
            <ModalImage
              key={index}
              src={URL.createObjectURL(imageFile) || ""}
              alt={`Selected Image ${index + 1}`}
            />
          ))}
        </Carousel>
      </ModalContent>

      <ButtonSection>
        <FirstButtonWrapper>
          {/* <Icon src={leftIcon} /> */}
          <div style={{ width: "48px", height: "20px" }}></div>
          <Button
            $button_type="primary"
            onClick={() => handleCreatePost()}
            loading={loading}
            disabled={loading}
          >
            Add
          </Button>
          <MediumIcon src={deleteIcon} onClick={() => onDeleteImage()} />
        </FirstButtonWrapper>
        <SecondButtonWrapper>
          <Button $button_type="colored" onClick={onClose}>
            Cancel
          </Button>
        </SecondButtonWrapper>
      </ButtonSection>
    </ModalOverlay>
  );
};

export default AddPostModal;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const ButtonSection = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 0px;
`;

const FirstButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 12px;
  gap: 32px;
`;
const SecondButtonWrapper = styled.div`
  margin: 0 36px;
`;
