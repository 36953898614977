import styled from "styled-components";
import { colors } from "../../../styles/style";
import blankProfilePicture from "../../../images/blankProfilePicture.jpg";
// import editIcon from "../../../images/editIcon.svg";

export const AccountPicture = () => {
  return (
    <Container>
      <ProfileImage alt="User" src={blankProfilePicture} />
      {/* <EditIcon alt="Camera Icon" src={editIcon} /> */}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  align-self: center;
  width: 210px;
  height: 210px;
  min-height: 210px;
  max-width: 100%;
  margin-top: 16px;
  @media (max-width: 370px) {
    width: 170px;
    height: 170px;
    min-height: 170px;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid ${colors.white};
  box-shadow: 0px 18px 30px 0px ${colors.warmthGrey};
`;

// const EditIcon = styled.img`
//   position: absolute;
//   bottom: -7%;
//   left: 50%;
//   transform: translateX(-50%);
//   width: 36px;
//   height: 36px;
//   padding: 8px;
//   border-radius: 12px;
//   background-color: ${colors.white};
// `;
