import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { colors } from "../../styles/style";
import { SmallIcon } from "./SharedStyles";
import { useBabyContext } from "../../context/BabyContext";

interface NavItemProps {
  to: string;
  iconSrc: string;
  activeIcon: string;
  alt: string;
  text: string;
  margin?: string;
  isActive: boolean;
  disabled?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
  to,
  iconSrc,
  alt,
  text,
  margin,
  isActive,
  activeIcon,
  disabled,
}) => {
  const { hasInvitation } = useBabyContext();

  return (
    <NavItemLink to={disabled ? "#" : to} margin={margin} disabled={disabled}>
      <IconWrapper>
        <SmallIcon src={isActive ? activeIcon : iconSrc} alt={alt} />
        {/* Show red dot if hasInvitation is true and text is "Settings" */}
        {hasInvitation && text === "Settings" && <RedDot />}
      </IconWrapper>
      <NavItemText>{text}</NavItemText>
    </NavItemLink>
  );
};

export default NavItem;

const NavItemLink = styled(NavLink)<{ margin?: string; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  margin: ${(props) => props.margin || "0"};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const NavItemText = styled.h5<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? colors.softBrown : colors.darkBrown)};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const IconWrapper = styled.div`
  position: relative;
`;

const RedDot = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
`;
