import React, { createContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { authenticateUser } from "../api";

type Auth = {
  authToken: string;
  authUser?: any;
  authenticate(authToken: string): void;
  logout(): void;
  hasActivePayment: boolean;
};

export const AuthContext = createContext<Auth>({
  authToken: "",
  authUser: undefined,
  logout: () => {},
  authenticate: (accessToken: string) => Promise.resolve(),
  hasActivePayment: false,
});

export const AuthProvider: any = ({ children }: any) => {
  const [authToken, setAuthToken] = useState<any>(
    localStorage.getItem("little-feather-token")
  );
  const [authUser, setAuthUser] = useState<any | null>(false);
  const [hasActivePayment, setHasActivePayment] = useState(false);

  const authenticate = async () => {
    if (authUser === false) {
      try {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            if (!user.emailVerified) {
              await logout(false);
              return;
            }
            const newToken = await user.getIdToken(true);
            localStorage.setItem("little-feather-token", newToken);
            const userData = await authenticateUser();
            if (userData.data) {
              setAuthUser(userData.data);
              setHasActivePayment(userData.data.activePayment);
              setAuthToken(newToken);
            }
          } else {
            if (localStorage.getItem("little-feather-token")) {
              await logout();
            } else {
              setAuthUser(null);
              setHasActivePayment(false);
            }
          }
        });
      } catch (err) {
        console.error("Error during authentication:", err);
        await logout();
      }
    }
  };

  const logout = async (reload = true) => {
    const auth = getAuth();
    await auth.signOut();
    localStorage.removeItem("little-feather-token");
    localStorage.removeItem("selected-baby");
    setAuthToken(null);
    setAuthUser(null);
    setHasActivePayment(false);
    if (reload) {
      window.location.reload();
    }
  };

  useEffect(() => {
    authenticate();

    const intervalId = setInterval(() => {
      authenticate();
    }, 120000); //2 minutes

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authToken,
        authUser,
        authenticate,
        logout,
        hasActivePayment,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
