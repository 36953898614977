import styled from "styled-components";
import { colors } from "../../../styles/style";

export const Headline = styled.h1`
  color: ${colors.warmthBrown};
  font-size: 40px;
  font-style: normal;
  font-weight: 450;
  line-height: 62px;
  letter-spacing: -0.6px;
  padding: 10px 0px;
`;

interface DescriptionProps {
  $padding?: string;
}
export const Description = styled.p<DescriptionProps>`
  color: ${colors.warmthBrown};
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  padding: ${(props) => props.$padding || "0px"};
`;

interface BackgroundImageProps {
  $blend_mode?: string;
  $position?: string;
  $right?: string;
  $top?: string;
  $left?: string;
  $bottom?: string;
  $width?: string;
  $transform?: string;
}
export const BackgroundImage = styled.img<BackgroundImageProps>`
  height: auto; // all same
  mix-blend-mode: ${(props) =>
    props.$blend_mode !== undefined ? props.$blend_mode : "color-burn"};
  position: ${(props) =>
    props.$position ||
    "absolute"}; // default absolute change to fixed if needed
  right: ${(props) => (props.$right !== undefined ? props.$right : "initial")};
  top: ${(props) => (props.$top !== undefined ? props.$top : "initial")};
  left: ${(props) => (props.$left !== undefined ? props.$left : "initial")};
  bottom: ${(props) =>
    props.$bottom !== undefined ? props.$bottom : "initial"};
  width: ${(props) => (props.$width !== undefined ? props.$width : "127px")};
  transform: ${(props) => props.$transform || "none"};
  max-width: 100%; // all same
`;

export const HeaderIcon = styled.img`
  position: absolute;
  top: 3%;
  left: 4%;
  width: 20px;
  height: 18px;
`;

export const GoBackButton = styled.div`
  color: ${colors.warmthBrown};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 30px 0;
`;

export const WelcomeText = styled.div`
  color: ${colors.warmthBrown};
  font-size: 27px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 62px;
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  margin-top: auto;
`;

export const SubHeadline = styled.h4`
  color: ${colors.darkBrown};
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 21.6px;
  letter-spacing: -0.3px;
`;
