import React, { useState } from "react";
import styled from "styled-components";
import goBackIcon from "../../../images/goBackIcon.svg";
import Button from "../../../helpers/components/Button";
import { EditModal } from "../../shared/EditModal";
import { SharedButton } from "../../settings/common/shared/SharedButton";
import penIcon from "../../../images/penIconBlack.svg";
import { CustomTextInput } from "../../../helpers/components/CustomInputs";
import { IDetails } from "./InformationSection";
import { BigHeaderText, FlexRowBetweenCenter } from "../../shared/SharedStyles";

interface AddNewMemberModalProps {
  showModal: boolean;
  handleSetShowModal: () => void;
  handleRefreshMembers: () => void;
  title: string;
  data: IDetails[];
}

export const AddOrUpdateInformation: React.FC<AddNewMemberModalProps> = ({
  showModal,
  handleSetShowModal,
  handleRefreshMembers,
  title,
  data,
}) => {
  const [newData, setNewData] = useState<IDetails[]>(data);

  const handleSubmitData = () => {
    console.log(newData);
  };

  return (
    <EditModal isOpen={showModal} onClose={handleSetShowModal}>
      <HeaderContainer>
        <HeaderIcon src={goBackIcon} onClick={handleSetShowModal} />
        <BigHeaderText>{title}</BigHeaderText>
        <div></div>
      </HeaderContainer>
      <InputsWrapper>
        {data.map((item, index) => (
          <CustomTextInput
            key={index}
            label={item.title}
            placeholder="Enter label"
            value={item.value?.toString()}
            onChange={(e) => {
              setNewData((prev) => {
                const newData = [...prev];
                newData[index].value = e.target.value;
                return newData;
              });
            }}
          />
        ))}
      </InputsWrapper>
      <ButtonsWrapper>
        <SharedButton
          iconSrc={penIcon}
          buttonText={"Done"}
          onClick={handleSubmitData}
        />
        <Button $button_type="colored" onClick={handleSetShowModal}>
          Cancel
        </Button>
      </ButtonsWrapper>
    </EditModal>
  );
};

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px 0;
  width: 100%;
`;

const HeaderIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
`;
