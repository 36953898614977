import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../styles/style";
import goBackIcon from "../../../images/goBackIcon.svg";
import Button from "../../../helpers/components/Button";
import addIcon from "../../../images/addIcon.svg";
import {
  BigHeaderText,
  FlexRowBetweenCenter,
  MediumIcon,
  ModalOverlay,
  SmallIcon,
  SmallestText,
} from "../../shared/SharedStyles";
import { useNavigate } from "react-router-dom";
import {
  addFirstYear,
  getFirstYearByBaby,
  updateFirstYear,
} from "../../../api";
import { useBabyContext } from "../../../context/BabyContext";
import { uploadImageToStorage } from "../../../common/helpers";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { firstYearData } from "../../../common/constants";
import { ImageCropper } from "./common/CropImage";
import {
  MilestoneButtonWrapper,
  MilestoneContainer,
  MilestoneSelectedImage,
  MilestoneWrapper,
} from "../common/SharedComponents";
import { AuthContext } from "../../../context/AuthContext";

export const FirstYearPage = () => {
  const navigate = useNavigate();
  const { hasActivePayment } = React.useContext(AuthContext);
  const { selectedBabyId } = useBabyContext();
  const [loading, setLoading] = useState(false);
  const [showImageInput, setShowImageInput] = useState(false);
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [selectedPosition, setSelectedPosition] = useState<number | null>(null);

  const [yearMonthNodes, setYearMonthNodes] = useState<any>(firstYearData);

  const goBackHandler = () => {
    navigate(-1);
  };

  const handleImageChange = async (event: any, position: number) => {
    const file = event.target.files[0];
    if (!file) return;
    setSelectedPosition(position);
    setShowCropperModal(true);
    setUploadedFile(file);
  };

  const handleImageUpload = async (file: any, selectedNode: any) => {
    setLoading(true);
    const uploadedImage = await uploadImageToStorage(
      file,
      "images/milestones/monthly-diary/"
    );
    if (yearMonthNodes[selectedNode.position]?.image) {
      await updateFirstYear(selectedBabyId, {
        baby_id: selectedBabyId,
        year: 2024,
        month: selectedNode.position + 1,
        image: uploadedImage.imageUrl,
      });
    } else {
      await addFirstYear({
        baby_id: selectedBabyId,
        year: 2024,
        month: selectedNode.position + 1,
        image: uploadedImage.imageUrl,
      });
    }
    fetchFirstYearData();
    setShowImageInput(false);
    setLoading(false);
  };

  const fetchFirstYearData = async () => {
    setLoading(true);
    await getFirstYearByBaby(selectedBabyId, 2024)
      .then(({ data }) => {
        setYearMonthNodes((prev: any) => {
          return prev.map((member: any) => {
            const memberData = data.find(
              (d: any) => d.month === member.position + 1
            );
            if (memberData) {
              return {
                ...member,
                image: memberData.image,
              };
            }
            return member;
          });
        });
      })
      .catch((err) => {});
    setLoading(false);
  };

  const croppedImageActionFinished = async (croppedImage: any) => {
    setShowCropperModal(false);
    if (selectedPosition === null) return;
    await handleImageUpload(croppedImage, selectedPosition as number);
    setUploadedFile(null);
  };

  useEffect(() => {
    fetchFirstYearData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <MilestoneContainer>
        <HeaderContainer>
          <MediumIcon src={goBackIcon} onClick={goBackHandler} />
          <BigHeaderText>My First Year</BigHeaderText>
          <div></div>
        </HeaderContainer>
        <MilestoneWrapper>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <NodeWrapper>
              {yearMonthNodes.map((member: any, index: number) => {
                let monthNode = yearMonthNodes.find(
                  (f: any) => f.position === member.position
                );
                if (!monthNode) {
                  monthNode = member;
                }
                if (member.position === 8) {
                  monthNode = member;
                }
                return (
                  <FirstRowWrapper key={index} $padding={monthNode?.padding}>
                    <ImageInputContainer
                      onClick={() => {
                        setShowImageInput(true);
                      }}
                      disabled={!hasActivePayment && monthNode?.image === null}
                    >
                      {showImageInput && (
                        <StyledInput
                          type="file"
                          accept="image/*"
                          onChange={(event) =>
                            handleImageChange(event, monthNode)
                          }
                          disabled={!hasActivePayment}
                        />
                      )}
                      {monthNode?.image ||
                      monthNode?.imageFile ||
                      monthNode?.imageUrl ? (
                        <MilestoneSelectedImage
                          src={
                            monthNode?.imageFile &&
                            monthNode.isUpdated &&
                            monthNode.position < 8
                              ? URL.createObjectURL(monthNode.imageFile)
                              : monthNode?.image
                          }
                          alt={`${monthNode.label}'s Image`}
                        />
                      ) : (
                        <SmallIcon src={addIcon} />
                      )}
                      {monthNode?.label && (
                        <IndexNumberCircle>{monthNode.label}</IndexNumberCircle>
                      )}
                    </ImageInputContainer>
                  </FirstRowWrapper>
                );
              })}
            </NodeWrapper>
          )}
        </MilestoneWrapper>
        <MilestoneButtonWrapper>
          <Button $button_type="colored" onClick={goBackHandler}>
            Go Back
          </Button>
        </MilestoneButtonWrapper>
      </MilestoneContainer>
      {showCropperModal && (
        <ModalOverlay>
          <ImageCropper
            image={uploadedFile}
            croppedImageAction={croppedImageActionFinished}
            closeModal={() => {
              setShowCropperModal(!showCropperModal);
              setUploadedFile(null);
            }}
          />
        </ModalOverlay>
      )}
    </React.Fragment>
  );
};

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const ImageInputContainer = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 2px solid ${colors.white};
  border-radius: 50%;
  cursor: pointer;
  background-color: ${colors.white};
  box-shadow: 0px 1.348px 14.149px 3.369px rgba(153, 158, 216, 0.2);
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`;

const IndexNumberCircle = styled.div`
  position: absolute;
  padding: 8px 14px;
  left: -30%;
  background-color: ${colors.warmthPink};
  border-radius: 100px;
  background: ${colors.warmthBrown};
  color: ${colors.white};
  ${SmallestText};
`;

interface NodePadding {
  $padding: string;
}

const FirstRowWrapper = styled.div<NodePadding>`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: ${(props) => props.$padding};
`;
const NodeWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 20px 0;
`;
const StyledInput = styled.input`
  display: none;
`;
